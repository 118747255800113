<template>
  <SideBar/>

  <div class="card main-content mx-3">
    <div class="grid mt-2">

        <div class="col-12">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Item Details
                </div>
                <div class="flex align-items-center justify-content-center">
                    <Button label="Back to Inventory" icon="pi pi-angle-left" severity="secondary" @click="$router.back()"/>
                </div>
            </div>
        </div>

        <div class="sm:col-12 md:col-3 lg:col-3">
            <div class="surface-card shadow-2 p-3 border-round-xl w-full mt-2 ml-2 mr-2">
                <div class="field mt-2 px-1">
                    <label>Name:</label>
                    <InputText v-model="itemName" class="w-full" disabled/>
                </div>

                <div class="field py-3 px-1">
                    <label>Category:</label>
                    <InputText v-model="itemCategory" class="w-full" disabled/>
                </div>
               
            </div>
            <div class="surface-card shadow-2 p-3 border-round-xl w-full mt-5 ml-2 mr-2 h-10rem">
                <div class="mt-2 px-1">
                    <label >Quantity</label>
                </div>
                <div class="mt-3 px-1">
                    <label class="text-3xl font-medium">
                        {{itemQty}}
                    </label>
                    <label v-if="itemQty>0" class="border-round-3xl ml-2 bg-green-100 pl-2 pr-3 py-1 " > In-Stock </label>
                    <label v-if="itemQty==0" class="border-round-3xl ml-2 bg-red-100 pl-2 pr-3 py-1 " > Out of Stock </label>
                </div>
                 <!-- <div >
                 </div> -->
            </div>
        </div>
        <div class="sm:col-12 md:col-9 lg:col-9">
            <div class="surface-card shadow-2 p-3 border-round-xl w-full mt-2 ml-4 mr-4 ">
            <TabView>
                <TabPanel header="Order Details">
                    <p class="m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </TabPanel>
                <TabPanel header="Ledger">
                    <p class="m-0">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                        ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                    </p>
                </TabPanel>

            </TabView>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import SideBar from '../sidebar/sideBarTwo.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
    components:{
        TabView,
        TabPanel,
        SideBar,
        InputText,
        Button,
    },
    data(){
        return{
            itemName:null,
            itemCategory:null,
            itemQty:4526,
        }
    },
}
</script>

<style>

</style>