<template>
    <!-- <Navbar/> -->
    <!-- <SideBar/> -->

    <div class="main-content">
            <div class="progress-spinner" v-if="spinnerLoadingStatus">
                <ProgressSpinner></ProgressSpinner>
            </div>

    <div class="grid">        
        <div class="sm:col-12 md:col-6 lg:col-3">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full"> 
                <div class="surface-card shadow-2 p-3 border-round-xl  w-full mt-4 mr-2 ml-3">
                    
                  <div class="field  mt-2 ml-2 w-full">
                    <i class="pi pi-wrench mr-1 font-medium" style="font-size: 1.5rem; color:#e50000"></i>  
                    <label class="text-3xl font-medium ml-2"> {{formatNumber(pendingInstallationCount)}}</label> <br>

                    <label class="text-500">Pending Installation</label>
                  </div>
                  <div class="w-full pt-4">
                    <MeterGroup :value="pendingInstallationMeter" class="mx-2"/>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div class="sm:col-12 md:col-6 lg:col-3">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full"> 
                <div class="surface-card shadow-2 p-3 border-round-xl  w-full mt-4 mx-2 sm:ml-3 sm:mr-2">
                    <div class="field mt-2 ml-2 w-full">
                        <i class="pi pi-cog pi-spin mr-1" style="font-size:1.5rem; color:#3383ff;"></i> 
                        <label class="text-3xl font-medium ml-2" >{{formatNumber(servicingDue)}}</label><br>
                        <label class="text-500">Servicings</label>
                    </div>
                    <div class="w-full pt-4">
                        <MeterGroup :value="servicingMeter" class="mx-2"/>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <div class="sm:col-12 md:col-6 lg:col-3">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full"> 
                <div class="surface-card shadow-2 p-3 border-round-xl  w-full mt-4 ml-2 md:ml-3 sm:ml-3 mr-2">
                    <div class="field  mt-2 ml-2 w-full">
                        <i class="pi pi-money-bill mr-1" style="font-size:1.5rem; color:#1ca500"></i> 
                        <label class="text-3xl font-medium ml-2">{{formatNumber(cmPendingBillCount)}}</label> <br>
                        <label class="text-500">Servicings pending to be billed</label>
                    </div>
                    <div class="mt-3">
                        <label class="text-lg">Value</label><br>
                        <hr class="hr-style mr-2">
                        <div class="flex justify-content-between flex-wrap mt-1">
                            <div class="flex align-items-center justify-content-center text-base">
                                <label class="mr-2">Ex-Gst: </label>
                                <label class="bg-green-100 border-round px-1 py-1">{{formatCurrency(pendingBillExGst)}}</label>
                            </div>
                            <div class="flex align-items-center justify-content-center mr-2">
                                <label class="mr-2">GST: </label>
                                <label class=" bg-green-100 border-round px-1 py-1">{{formatCurrency(pendingBillGst)}}</label>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
          </div>
        </div>

        <div class="sm:col-12 md:col-6 lg:col-3">
          <div class="flex ">
            <div class="flex-initial flex justify-content-end w-full "> 
                <div class="flex align-items-center justify-content-center text-base ml-2 md:ml-3 sm:ml-3 mr-3 border-2 border-dashed border-300 border-round-xl border-solid mt-4">
                    <div class="field mt-2 mr-4 ml-3 mb-4">
                        <label class="text-xl font-medium">Servicing Bills</label>
                        <router-link to="/new-servicing-bill" rel="noopener">
                            <Button label="Create Bill" icon="pi pi-plus" raised class="px-4 mt-3 mr-3 w-full" />
                        </router-link>
                        <router-link to="/servicing-bills" rel="noopener">
                            <Button label="CMC Bills" icon="pi pi-list" text raised class="w-full px-4 mr-3 mt-3" />
                        </router-link>
                    </div>
                </div>
            </div>
          </div>
        </div>



        <div class="sm:col-12 md:col-12 lg:col-12">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full ml-3 mr-3 "> 
                <div class="surface-card shadow-2 p-3 border-round-xl w-full mt-4 ">

                  <div class="field font-medium text-lg w-full">
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center font-semibold text-lg  ml-2">                                        
                                Equipment Installations                                       
                        </div>
                        <div class="flex align-items-center justify-content-center mr-3 mt-2">
                                                       
                            <Button label="Add Installation" size="small" raised @click="showDialog" :disabled="installationBtn"/>
                        </div>                                     
                    </div>
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center">                                        
                                <Button label="Export" icon="pi pi-file-export" raised  size="small" class="ml-2" @click="exportCSV" v-tooltip.top="'Excel format'"/>                                       
                        </div>
                        <div class="flex align-items-center justify-content-center">            
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" class=" mr-3 mb-0 mt-2" size="small"/>
                        </div>
                    </div>
                  </div>

                  <div class="field col">
                    <DataTable ref="installationsList" :value="tableData" stripedRows  size="small" :loading="tableLoading" v-model:filters="filters"  dataKey="id" paginator :rows="50" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="500px">

                        <template #empty>
                            <div class="flex ">
                                <div class="flex-initial flex justify-content-center w-full"> 
                                    <label class="font-medium" >No data</label>
                                </div>
                            </div>
                        </template>

                        <Column field="location" header="Location"></Column>   
                        <Column exportable field="billDocLocation" header="Bill Location"></Column>                                    
                        <Column field="item" header="Item"></Column>
                        <Column field="poNo" header="PO No."></Column>
                        <Column field="installedQty" header="Quantity"></Column>
                        <Column field="manufacturer.name" header="Manufacturer"></Column>
                        <Column field="modelNo" header="Model No"></Column>
                        <Column field="partSerialNo" header="Serial No"></Column>
                        <Column field="installationDate" header="Installation Date"></Column>
                        <Column field="endOfSupport" header="End of Support"></Column>
                        <Column field="engineer.name" header="AMS Representative"></Column>
                        <Column hidden exportable="true" field="userContact" header="User Cont."></Column>
                        <Column header="CMC">
                            <template #body="slotProps">                                          
                                <router-link :to="{
                                    name: 'equipment-cmc-details',
                                    params:{
                                        id:slotProps.data.id,
                                    }
                                }" rel="noopener">
                                    <Button label="CMC" text raised size="small" v-tooltip.top="'CMC details of equipment'"/>
                                </router-link>
                            </template>
                        </Column>                                    
                        <Column header="Actions">
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" size="small" text rounded raised severity="danger" @click="showDeleteDialog(slotProps.data)" v-tooltip.top="'Delete Installation'"></Button>
                            </template>
                        </Column>                                    
                    </DataTable>                    
                  </div>
                </div>
            </div>
          </div>

        </div>
    </div>

        <!-- dialog start -->
                                <Dialog :visible="dialogVisibility"  :modal="true" :style="{ width: '55rem' }" :closable="false" :dismissableMask="true">
                                    <template #header >
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex align-items-center justify-content-center text-xl font-semibold">
                                                {{dialogHeader}}
                                            </div>
                                            <div class="flex align-items-center justify-content-center">
                                            </div>                                    
                                        </div>
                                    </template>

                                    <hr class="w-full"> 
                                    <div class="mb-4">
                                        <label class="text-lg font-semibold mb-2">
                                            Equipment Details
                                            <span class="text-sm text-400">Options marked as <span class="text-red-500">*</span> are mandatory</span>
                                        </label>
                                    </div>

                                    <div class="formgrid grid mt-2">
                                        <div class="field col">
                                            <label class="w-full font-semibold">Department Order:<span class="text-red-500">*</span> </label>
                                            <Dropdown class="w-full mb-3" v-model="selectedDeptOrder" filter :options="deptOrdersOnly" optionLabel="poNo" placeholder="Select an order" showClear @change="filterEquipments"/>

                                            <label class="w-full font-semibold">Equipment:<span class="text-red-500">*</span> </label> 
                                            <Dropdown class="w-full" v-model="selectedEquipment" filter :options="filteredEquipment" optionValue="id" optionLabel="name" placeholder="Select an equipment" @change="updateDialogData"/>
                                            
                                            <label class="text-sm font-medium text-400 w-full m-0 p-0"> <span class="text-500">PO Date:</span> {{deptPoDate}}</label>
                                        </div>
                                        <div class="field col ">
                                            <div class="flex justify-content-between flex-wrap ">
                                                <div class="flex align-items-center justify-content-center">
                                                    <label class="w-full font-semibold">Delivered Location:<span class="text-red-500">*</span></label>
                                                </div>
                                                <div class="flex align-items-center justify-content-center mr-2">
                                                    <label class="w-full font-semibold">Qty:</label>
                                                    {{locationQuantity}}
                                                </div>

                                            </div>
                                            <Dropdown class="w-full mt-2" v-model="selectedLocation" filter :options="deliveredLocations"  optionLabel="location.name" placeholder="Select delivery location" @change="updateDeliveredQty">
                                            </Dropdown>   

                                            <div class="flex justify-content-start flex-wrap mt-4">
                                                <div class="flex align-items-center justify-content-center">
                                                    <label class="w-full font-semibold">Bill doc location:<span class="text-red-500">*</span></label>
                                                </div>   
                                            </div>
                                            <div class="flex justify-content-start flex-wrap mt-1">
                                                <div class="flex align-items-center justify-content-center w-full">
                                                    <InputText v-model="billLocation" class="w-full" placeholder="Installation short location"/>
                                                </div>   
                                            </div>               
                                            
                                        </div>
                                    </div>

                                    <div class="formgrid grid">
                                        <div class="field col">
                                            <label class="w-full font-semibold pt-2">Delivered Variant:<span class="text-red-500">*</span></label>
                                            <Dropdown class="w-full" v-model="deliveredQty" filter :options="locationQty"  placeholder="Variant delivered" @change="updateModelSr">
                                                <template #value="slotProps">
                                                    <div v-if="slotProps.value" class="flex align-items-center">
                                                        <div>{{ slotProps.value.pendingInstallation }}/ {{ slotProps.value.modelNo }} / {{ slotProps.value.partSerialNo }}</div>
                                                    </div>
                                                </template>
                                                <template #option="slotProps">
                                                    <div class="flex align-items-center">
                                                        <div>{{ slotProps.option.pendingInstallation }}/ {{ slotProps.option.modelNo }} / {{ slotProps.option.partSerialNo }}</div>
                                                    </div>
                                                </template>
                                            </Dropdown>
                                        </div>
                                        <div class="field col">
                                            <label class="w-full font-semibold pt-2">Installed Qty:<span class="text-red-500">*</span></label>
                                            <InputNumber class="w-full" v-model="installedQty" :min="1" :max="999999" showButtons />
                                        </div>
                                    </div>

                                    <ComponentOne v-if="cmcStatus">
                                        <div class="formgrid grid" >
                                            <div class="field col">
                                                <Fieldset>
                                                    <template #legend>
                                                        <div class="flex align-items-center pl-2">
                                                            <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                                                            <span class="font-bold ml-1"> CMC</span>
                                                        </div>
                                                    </template>

                                                    <DataTable :value="cmcs" showGridlines size="small" dataKey="id" :rows="10" scrollable scrollHeight="150px">

                                                        <Column field="year" header="Year"/>
                                                        <Column field="rate" header="Rate">
                                                        <template #body="slotProps">
                                                            {{formatCurrency(slotProps.data.rate)}}
                                                        </template>
                                                        </Column>
                                                        <Column field="gst" header="GST(%)"/>
                                                    </DataTable>
                                                    
                                                </Fieldset>
                                            </div>
                                        </div>
                                    </ComponentOne>


                                    <ComponentTwo v-if="!cmcStatus">
                                    <div class="formgrid grid" >
                                        <div class="field col">
                                            <Fieldset>
                                                <template #legend>
                                                    <div class="flex align-items-center pl-2">
                                                        <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                                                        <span class="font-bold ml-1">Add CMC</span>
                                                    </div>
                                                </template>

                                                <div class="flex justify-content-end flex-wrap ">
                                                    <div class="flex align-items-center justify-content-center ">
                                                        <Button icon="pi pi-plus" severity="success" size="small" text raised @click="addNewCmc" disabled/>
                                                    </div>
                                                </div>

                                                <div class="flex" v-for="(item, index) in newCmcs" :key="index">
                                                    <div class="flex align-items-center justify-content-center w-8 mt-2">
                                                        <InputNumber class="w-full" v-model="cmcYear[index]" placeholder="Year of CMC" @input="updateNewCmcYear(index, $event)"/>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center mt-2 ml-1">
                                                        <InputNumber class="w-full" v-model="cmcCost[index]" placeholder="Cost of CMC" @input="updateNewCmcCost(index, $event)"/>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center mt-2 ml-1">
                                                        <InputNumber class="w-full" v-model="cmcGst[index]" placeholder="GST (%) on cost" @input="updateNewCmcGst(index, $event)"/>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-centermt mt-2 ml-1">
                                                        <Button icon="pi pi-trash" text rounded severity="danger" @click="deleteCmcItem(index)"/>
                                                    </div>
                                                </div>

                                            </Fieldset>
                                        </div>
                                    </div>

                                    </ComponentTwo>

                                    <div class="formgrid grid">
                                        <div class="field col">
                                            <label class="w-full font-semibold">Model No:</label>
                                            <InputText v-model="modelNo" class="w-full" placeholder="Equipment Model No."/>
                                        </div>
                                        <div class="field col">
                                            <label class="w-full font-semibold">Serial No:</label>
                                            <InputText class="w-full" v-model="serialNo" placeholder="Serial/Part No."/>
                                        </div>
                                    </div>

                                    <div class="formgrid grid">
                                        <div class="field col">                                    
                                            <label class="w-full font-semibold">Manufacturer:<span class="text-red-500">*</span></label>
                                            <Dropdown editable class="w-full" v-model="manufacturer" filter :options="equipmentManufacturers" optionValue="id" optionLabel="name" placeholder="Manufacturer of equipment"/>
                                        </div>
                                        <div class="field col">
                                            <label class="w-full font-semibold">Date:<span class="text-red-500">*</span></label>
                                            <Calendar class="w-full" v-model="installationDate" placeholder="Installation date" dateFormat="dd/mm/yy"/>
                                        </div>
                                    </div>

                                    <div class="formgrid grid">
                                        <div class="field col">
                                            <label class="w-full font-semibold">AMS Representative:<span class="text-red-500">*</span></label>
                                            <Dropdown editable class="w-full" v-model="installationEngineer" filter :options="engineers" optionValue="id" optionLabel="name" placeholder="Select representative"/>
                                        </div>
                                    </div>

                                    <!-- <div class="flex"> -->
                                        <div class="flex  w-full">
                                            <div class="surface-card shadow-2 p-3 border-round-lg  w-full mb-3">
                                                <div class="flex justify-content-between flex-wrap ">
                                                    <div class="flex align-items-center justify-content-center font-semibold text-lg my-2 mx-2 ">                                        
                                                                Items Description <span class="font-normal text-sm ml-2 text-400"> <span class="text-red-500">*</span>Add at-least 1 item </span>                                    
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center ">
                                                        <Button label="Add item" severity="success" size="small" text raised @click="addItems"/>
                                                    </div>
                                                </div>

                                                <div class="flex" v-for="(item, index) in itemsArr" :key="index">
                                                    <div class="flex align-items-center justify-content-center w-8 mt-2">
                                                        <InputText class="w-full" v-model="itemName[index]" placeholder="Item description" @change="updateItemName(index)"/>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center mt-2 ml-1">
                                                        <InputNumber class="w-full" v-model="itemQty[index]" placeholder="Quantity" @input="updateItemQty(index, $event)"/>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-centermt mt-2 ml-1">
                                                        <Button icon="pi pi-trash" text rounded severity="danger" @click="deleteItem(index)"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- </div> -->

                                    <div class="formgrid grid mt-1">
                                        <div class="field col">
                                            <label class="w-full font-semibold">Remarks:</label>
                                            <Textarea class="w-full" v-model="remarks" rows="5"  />
                                        </div>
                                    </div>

                                    <hr>
                                    <div class="mb-4">
                                        <label class="text-lg font-semibold mb-2">
                                            User Details                                    
                                        </label>
                                    </div>

                                    <div class="formgrid grid">
                                        <div class="field col">
                                            <label class="w-full font-semibold">Name:</label>
                                            <InputText v-model="userName" class="w-full" placeholder="User full name"/>
                                        </div>
                                        <div class="field col">
                                            <label class="w-full font-semibold">Contact:</label>
                                            <InputMask v-model="userContact" mask="9999999999" placeholder="XXXXX-XXXXX" class="w-full"/>
                                        </div>
                                    </div>

                                    <template #footer>
                                        <div class="flex justify-content-end flex-wrap">
                                            <div class="flex align-items-center justify-content-center">
                                                <Button label="Save" raised severity="success" size="small" @click="saveInstallation" class="mx-2"></Button>
                                                <Button label="Cancel" text raised severity="secondary" size="small" @click="closeDialog"></Button>
                                            </div>
                                        </div>
                                    </template>

                                </Dialog>
                                <!-- dialog end -->


                                <Dialog :visible="confirmDeleteVisibility" modal :closable="false" header="Are you sure?" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                
                                    <div class="flex">
                                        <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i></div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <p>
                                                Are you sure you want to revert installation?
                                            </p>
                                        </div>                              
                                    </div>

                                    <div class="flex justify-content-end flex-wrap">
                                        <Button class="my-1 py-2 mr-2 px-5" label="Yes" raised severity="danger" @click="deleteInstallation" />
                                        <Button class="flex align-items-center justify-content-center my-1 px-5 " size="small" label="No" severity="secondary" text raised @click="closeDeleteDialog"></Button>
                                    </div>
                                </Dialog>

    </div>


</template>

<script>
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import Fieldset from 'primevue/fieldset'
import Textarea from 'primevue/textarea'
import { addEquipInstallation, cancelEquipmentInstallation, deleteEquipmentInstallation, deptOrderOnly, equipmentInstallationDashData, fetchEquipmentManufacturers, fetchInstallationEngineers, fetchPendingEquipmentsInstallation, getEquipmentInstallations } from '@/services/apiService'
import ProgressSpinner from 'primevue/progressspinner'
import { FilterMatchMode } from 'primevue/api'
import MeterGroup from 'primevue/metergroup';

export default {
    
    components:{
        Button,
        DataTable,
        Column,
        Dialog,
        InputText,
        Dropdown,
        Calendar,
        InputMask,
        InputNumber,
        Fieldset,
        Textarea,
        ProgressSpinner,
        MeterGroup,
    },

    data(){
        return{
            spinnerLoadingStatus:false,
            pendingInstallationCount:0,
            tableData:[],
            tableLoading:false,
            tableLoading2:false,
            dialogVisibility:false,
            dialogHeader:"Dialog header",
            // deptPoNo:null,
            deptPoDate:null,
            deptOrdersOnly:null,
            selectedDeptOrder:null,
            equipments:[],
            filteredEquipment:[],
            deliveredLocations:[],
            deliveredQty:null,
            tempDeliveredQty:[],
            locationQty:[],
            locationQuantity:0,
            engineers:[],
            selectedEquipment:null,
            selectedLocation:null,
            manufacturer:null,
            equipmentManufacturers:[],
            installationDate:null,
            modelNo:null,
            serialNo:null,
            billLocation:null,
            installationEngineer:null,
            itemsArr:[],
            itemName:[],
            itemQty:[],
            name:null,
            qty:0,
            cmcs:[],
            cmcStatus:false,
            cmcPresent:false,
            newCmcs:[],
            cmcYear:[],
            cmcCost:[],
            cmcGst:[],
            remarks:null,
            installationBtn:true,
            installedQty:1,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                location: { value: null, matchMode: FilterMatchMode.IN },
                item: { value: null, matchMode: FilterMatchMode.IN },
                poNo: { value: null, matchMode: FilterMatchMode.IN },
                'manufacturer.name': { value: null, matchMode: FilterMatchMode.IN },          
                modelNo: { value: null, matchMode: FilterMatchMode.IN },
                partSerialNo: { value: null, matchMode: FilterMatchMode.IN },
               'engineer.name': { value: null, matchMode: FilterMatchMode.IN },
            },
            confirmDeleteVisibility:false,
            cancelDialogVisibility:false,
            cancelDialogHeader:'Dialog header',
            selectedEquipments:null,
            equipId:null,
            pendingInstallationMeter:[],
            servicingDue:0,
            servicingMeter:[],
            cmPendingBillCount:0,
            pendingBillExGst:0.00,
            pendingBillGst:0.00,
        }
    },

    methods:{
        fetchDashboardData(){
            equipmentInstallationDashData().then((res)=>{               
                
                let completedPerc = ((res.data[1].totalInstallations - res.data[0].pendingInstallation) / res.data[1].totalInstallations) * 100; 
                let pendingPerc = (res.data[0].pendingInstallation / res.data[1].totalInstallations) * 100;

                this.pendingInstallationMeter = [
                { label: 'Completed - '+(res.data[1].totalInstallations - res.data[0].pendingInstallation), color:'#a6eb9f', value: completedPerc }, 
                { label: 'Pending', color: '#ffaaaa', value: pendingPerc },
                ]

                this.pendingInstallationCount = res.data[0].pendingInstallation;

                // upcoming and over due servicing count
                 
                this.servicingDue = res.data.cmcDue + res.data.cmcOverDue;
                let cmcDuePerc = (res.data.cmcDue / this.servicingDue) * 100;
                let cmcOverDuePerc = (res.data.cmcOverDue / this.servicingDue) * 100;

                this.servicingMeter = [
                    {label:'Due in 14 days - '+res.data.cmcDue, color:'#ffcf78', value:cmcDuePerc},
                    {label:'Over due '+res.data.cmcOverDue, color:'#ffaaaa', value:cmcOverDuePerc},
                ]
                
                this.cmPendingBillCount = res.data.cmcPendingBillingCount;
                this.pendingBillExGst = res.data.pendingCmcExGst;
                this.pendingBillGst = res.data.pendingCmcGst;

            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            })
        },
        fetchEquiupmentInstallations(){  
            this.tableLoading = true;
            getEquipmentInstallations().then((res)=>{                
                this.tableData = res.data;
                this.tableLoading = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.tableLoading = false;
            })
        },
        fetchEquipmentsPendingInstallation(){
            this.equipments = [];
            this.filteredEquipment = [];
            this.tableLoading2 = true;
            fetchPendingEquipmentsInstallation().then((res)=>{
                res.data.forEach(e => {
                    if(e.deliveredQty.length > 0){
                        this.equipments.push(e);
                    }
                });               

                this.filteredEquipment = this.equipments               
                
                this.tableLoading2 = false;
                this.installationBtn = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            });
        },
        fetchDeptOrders(){
            deptOrderOnly().then((res)=>{
                this.deptOrdersOnly = res.data
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            })
        },
        filterEquipments(){
            if(this.selectedDeptOrder==null){
                this.filteredEquipment = this.equipments
                this.selectedEquipment = null
                // this.deptPoNo = null
                this.deptPoDate = null
                return 
            }

            this.deptPoDate = this.selectedDeptOrder.date
            this.filteredEquipment = []

            this.equipments.forEach(e => {
                if(e.poNo==this.selectedDeptOrder.poNo){
                    this.filteredEquipment.push(e);
                }
            });
            
        },
        showDialog(){
            this.fetchEquipmentManufacturers();
            this.fetchAmsRepresentatives();
            this.tableLoading2 = false;
            this.dialogHeader = "Installation details";
            return this.dialogVisibility = true;
        },
        closeDialog(){

            this.selectedDeptOrder = null;
            this.billLocation = null;
            this.dialogVisibility = false;
            this.dialogHeader = null;
            this.selectedEquipment = null;
            this.selectedLocation = null;
            this.deliveredLocations = [];
            this.locationQty = [];
            this.locationQuantity = 0;

            this.manufacturer = null;
            this.installationDate = null;
            this.modelNo = null;
            this.serialNo = null;
            this.installationEngineer = null;
            this.itemsArr = [];
            this.itemName = [];
            this.itemQty = [];
            this.cmcStatus = false;
            this.deliveredQty = null;
            // this.deptPoNo = null;
            this.deptPoDate = null;
            this.remarks = null;
            this.userName = null;
            this.userContact = null;

            this.fetchEquipmentsPendingInstallation();
        },
        fetchEquipmentManufacturers(){
            fetchEquipmentManufacturers().then((res)=>{
                this.equipmentManufacturers = res.data;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            })
        },
        fetchAmsRepresentatives(){
            fetchInstallationEngineers().then((res)=>{
                this.engineers = res.data;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            })
        },
        updateDialogData(){    
            
            this.modelNo = null;
            this.serialNo = null;

            this.locationQuantity = 0;
            this.locationQty = [];

            this.selectedLocation = null;
            this.deliveredQty = null;
            
            this.equipments.every(e => {
                if(e.id == this.selectedEquipment){    
                    // this.deptPoNo = e.poNo;
                    // this.deptPoDate = e.poDate;
                    this.deliveredLocations = e.deliveryLocations;
                    this.tempDeliveredQty = e.deliveredQty;
                    this.cmcs = e.cmc;
                    if(e.cmc.length > 0){
                        this.cmcStatus = true;
                    }
                    if(e.cmc.length == 0){
                        this.cmcStatus = false;
                    }
                    return false;
                }
                return true;
            });
        },
        updateDeliveredQty(){ 
            
            this.modelNo = null;
            this.serialNo = null;
            
            this.locationQuantity = this.selectedLocation.quantity;
            this.deliveredQty = null;
            this.locationQty = [];
            this.tempDeliveredQty.forEach(e => {
                if(this.selectedLocation["location.locationId"] == e.locationId){
                    this.locationQty.push(e);
                }
            });            
                    
        },
        updateModelSr(){
            this.modelNo = this.deliveredQty.modelNo;
            this.serialNo = this.deliveredQty.partSerialNo;            
        },
        addItems(){
            this.itemsArr.push({
                name:null,
                qty:0
            });
        },
        updateItemName(index){            
            let item = this.itemsArr[index];
            item.name = this.itemName[index];            
        },
        updateItemQty(index, event){
            let item = this.itemsArr[index];
            item.qty = event.value;
        },
        deleteItem(index){
            this.itemsArr.splice(index, 1);
            this.itemName.splice(index, 1);
            this.itemQty.splice(index, 1);        
        },
        addNewCmc(){
            this.newCmcs.push({
                year:0,
                cost:0,
                gst:0,
            });
        },
        updateNewCmcYear(index, event){
            let cmc = this.newCmcs[index];
            cmc.year = event.value;
        },
        updateNewCmcCost(index, event){
            let cmc = this.newCmcs[index];
            cmc.cost = event.value;
        },
        updateNewCmcGst(index, event){
            let cmc = this.newCmcs[index];
            cmc.gst =  event.value;
        },
        deleteCmcItem(index){
            this.newCmcs.splice(index, 1);
            this.cmcYear.splice(index, 1);
            this.cmcCost.splice(index, 1);
            this.cmcGst.splice(index, 1);
        },
        saveInstallation(){
        
            if(!this.selectedEquipment==null || this.selectedLocation==null || this.manufacturer==null || this.installationDate==null || this.installationEngineer==null || this.deliveredQty == null || this.billLocation == null){
                return this.showErrorToast({'message':'Field marked with red * are mandatory!'});
            }
            if(this.itemsArr.length==0){
                return this.showErrorToast({'message':'Please add at-least 1 item description'});
            }

            this.spinnerLoadingStatus = true;

            const data = {
                'equipment':this.selectedEquipment,
                'deliveredLocation':this.selectedLocation,
                'billLocation':this.billLocation,
                'deliveredVariant':this.deliveredQty,
                'installedQty':this.installedQty,
                'modelNo':this.modelNo,
                'serialPartNo':this.serialNo,
                'manufacturer':this.manufacturer,
                'installationDate':this.installationDate.getDate()+'-'+(this.installationDate.getMonth()+1)+'-'+this.installationDate.getFullYear(),
                'installationEngineer':this.installationEngineer,
                'itemsDescriptionArr':this.itemsArr,
                'remarks':this.remarks,
                'newCmc':this.newCmcs,
                'rocCmc':this.cmcs,
                'userName':this.userName,
                'userContact':this.userContact,
            }            

            // this.spinnerLoadingStatus = true;
            this.closeDialog();

            addEquipInstallation(data).then((res)=>{
                this.showSuccessToast(res.data);
                // this.spinnerLoadingStatus = false;
                this.fetchEquiupmentInstallations();
                this.fetchEquipmentsPendingInstallation();
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });

            
        },
        showDeleteDialog(data){
            this.equipId = data.id;
            this.confirmDeleteVisibility = true;
        },
        deleteInstallation(){
            this.spinnerLoadingStatus = true;
            this.closeDeleteDialog();
            deleteEquipmentInstallation(this.equipId).then((res)=>{
                this.showSuccessToast(res.data);   
                this.fetchEquiupmentInstallations();
                this.fetchEquipmentsPendingInstallation();                
                this.fetchEquipmentManufacturers();
                this.spinnerLoadingStatus = false;

            }).catch((error)=>{
                if(error.response.status==409){
                    this.showWarningToast(error.response.data)
                }
                else{
                    this.showErrorToast(error.response.data);
                }
                this.spinnerLoadingStatus = false;
            })
        },
        closeDeleteDialog(){
            this.confirmDeleteVisibility = false;
        },
        // showCancelInstallationDialog(){
        //     this.cancelDialogVisibility = true;
        //     this.cancelDialogHeader = 'List of Equipments'          
        // },
        // closeCancelDialog(){
        //     this.cancelDialogVisibility = false;
        //     this.selectedEquipments = null;
        // },
        cancelInstallation(){
            const newArr = this.selectedEquipments.map( e =>{
                return e.id
            })          
        
            cancelEquipmentInstallation(newArr).then((res)=>{
                this.showSuccessToast(res.data)   
                this.closeCancelDialog()           
            }).catch((error)=>{
                this.showErrorToast(error.response.data)              
            })          
        },
        showWarningToast(warning){
            this.$toast.add({ severity: 'warn', summary: 'Warning', detail:warning.message, life: 4000 });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 2500 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 2500 });
        },
        formatCurrency(value) {
                return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        formatNumber(value) {
                return value.toLocaleString('en-IN');
        },
        exportCSV() {
            this.$refs.installationsList.exportCSV();
        },
    },

    mounted(){   
        this.fetchDashboardData();     
        this.fetchEquiupmentInstallations();
        this.fetchEquipmentsPendingInstallation();   
        this.fetchDeptOrders();     
        this.fetchEquipmentManufacturers();
    },

}
</script>

<style>


hr.hr-style{
    border-top: 2px dashed #d5d8dc;
}
</style>