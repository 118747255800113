<template>
<!-- <Navbar/> -->
<SideBar/>

<div class="card main-content mt-3 mx-3">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="">
          <Card>
            <template #title>
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center">
                            Inventory
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </div>
                        
                    </div>
                    <div class="flex justify-content-between flex-wrap mt-2">
                        <div class="flex align-items-center justify-content-center">
                          <Button icon="pi pi-external-link" label="Export" size="small"  raised class="mx-2" @click="exportData($event)" v-tooltip.top="'Export CSV'"/>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                          <Button label="Add Stock" icon="pi pi-plus" severity="info" size="small" disabled/>
                        </div>
                    </div>
                </template>
                <template #content>

                  <DataTable ref="stockList" :value="stockArr" :size="small" :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="100" :rowsPerPageOptions="[50, 100, 500, 1000]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" stripedRows 
                        @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" :globalFilterFields="['name','category']" filterDisplay="menu" scrollable scrollHeight="75vh">
                      
                          <Column header="Sl No." style="width:5%;">
                            <template #body="slotProps">
                                {{ slotProps.index + 1 }}
                            </template>
                          </Column>
                          <Column field="name" header="Item">
                            <template #body="slotProps">
                              <!-- <Button :label="slotProps.data.name" link @click="getItemDetails(slotProps.data)"></Button> -->
                              <router-link :to="{
                                  name: 'inventoryItemDetails',
                                  params:{
                                      id:slotProps.data.id,
                                  }
                              }" rel="noopener">
                                  {{slotProps.data.name}}
                              </router-link>
                                <!-- {{slotProps.data.name}} -->
                            </template>
                            <template #filter="{ filterModel }">
                              <InputText v-model="filterModel.value" class="p-column-filter" />
                            </template>
                          </Column>
                          <Column field="category" header="Category" >
                            <template #body="slotProps">
                                {{slotProps.data.category}}
                            </template>
                            <template #filter="{ filterModel }">
                                  <Dropdown v-model="filterModel.value" :options="categories" placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                                      <template #option="slotProps">
                                          <label>{{slotProps.option}}</label>
                                      </template>
                                  </Dropdown>
                              </template>
                          </Column>
                          <!-- <Column field="rocItem" header="Roc"  />
                          <Column field="nonRocItem" header="Non-Roc" /> -->
                          <Column field="packing" header="Packing" />
                          <Column field="store" header="Received at" />
                          <Column field="quantity" header="Quantity">
                            <template #body="slotProps">
                                {{formatNumber(slotProps.data.quantity)}}
                            </template>                          
                          </Column>
                  </DataTable>
                </template>
          </Card>
        </div>
</div>
  
</template>

<script>
import { getCategories, storeStock } from '@/services/apiService'
// import Navbar from '../header/headerNavbar.vue'
import ProgressSpinner from 'primevue/progressspinner'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import SideBar from '../sidebar/sideBarTwo.vue'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'

export default {
  components:{
    // Navbar,
    SideBar,
      ProgressSpinner,
      Card,
    DataTable,
    Column,
    InputText,
    Dropdown,
    Button,
    },

    data(){
      return{
        stockArr:[],
        spinnerLoadingStatus:false,
        expandedRows:[],
        filters: null,
            // filters: {
            //     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //     name: { value: null, matchMode: FilterMatchMode.IN },
            // },
        categories:[],
      }
    },

    methods:{
      getStoreStock(){
        this.spinnerLoadingStatus = true;
        storeStock().then((res)=>{
          console.log('store stock');
          console.log(res.data);
          
          this.stockArr = res.data;
        this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
        })
      },
      initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                category: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            };
        },
      showErrorToast(error){
          this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
      },
      showSuccessToast(data){
          this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
      },
      formatNumber(value) {
          return value.toLocaleString('en-IN');
      },
      exportData(){
          this.$refs.stockList.exportCSV();
      },
    },
    created(){
      this.initFilters()
    },
    mounted(){
      this.getStoreStock();

      getCategories().then((res)=>{
        res.data.forEach(e => {
          this.categories.push(e.label)
        });
      })
    }
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>