<template>
  <!-- <Navbar/> -->
  <SideBar/>

  <div class="card main-content">
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>

    <div class="surface-ground ">
        <Card>
        <template #content>

         <DataTable :value="filteredBills" :size="small" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" scrollable scrollHeight="80vh" :globalFilterFields="['dept.shortCode', 'procOfficer']" v-model:filters="filters" filterDisplay="menu">
                <template #header >
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span class="text-xl text-900 font-bold">Bill List <Tag value="DRAFT" severity="warning" class="mx-3"/> </span>
                    <div class="flex justify-content-end">
                        <InputText v-model="filterQuery" placeholder="Keyword Search" size="small"/>
                        <Button class="ml-2" icon="pi pi-filter" severity="success" size="small" @click="globalFilter" v-tooltip.top="'Filter'"/>
                        <Button class="ml-2" icon="pi pi-filter-slash" size="small" outlined severity="success" @click="clearFilter" v-tooltip.top="'Clear Filter'"/>
                    </div>
                     
                </div>
            </template>

            <Column expander style="width: 5rem"/>
            <Column field="inv_no" header="Bill No">
                <template #body="slotProps">
                    <router-link @click="billDoc(slotProps.data)" to="/bill-pdf" >{{slotProps.data.inv_no}}</router-link>
                </template>
            </Column>
            <Column field="date" header="Bill Date" />
            <Column field="deptPoNo" header="PO No." />
            <Column field="deptPoDate" header="PO Date" />            
            <Column field="procOfficer" header="Proc. Officer">
                <template #body="slotProps">
                    {{slotProps.data.procOfficer}}
                </template>
                <template #filter="{ filterModel }">
                    <Dropdown v-model="filterModel.value" :options="procOfficersArr"  placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                        <template #option="slotProps">
                            <label >{{slotProps.option}}</label>
                        </template>
                    </Dropdown>
                </template>
            </Column>            
            <Column field="dept.shortCode" header="Department" >
                <template #body="slotProps">
                    {{slotProps.data.dept.shortCode}}
                </template>
                <template #filter="{ filterModel }">
                    <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Filter by DEPT" />
                </template>
            </Column>
            <Column field="cgst" header="GST" >
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.cgst + slotProps.data.sgst)}}
                </template>
            </Column>

            <Column field="exGstTotal" header="Ex-GST Total">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.exGstTotal)}}
                </template>
            </Column>
            <Column field="adjstValue" header="Adjst Value" ></Column>
            <Column field="grandTotal" header="Grand Total" >
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.grandTotal)}}
                </template>
            </Column>
            <Column header="Action" style="width:10%">
                <template #body="slotProps" >
                    <Button class="mr-1" size="small" icon="pi pi-check" text rounded raised severity="success" v-tooltip.top="'Approve BILL'" @click="showBillApprovalDialog(slotProps.data)"/>
                    <Button icon="pi pi-times"  size="small" text rounded raised severity="danger" v-tooltip.top="'Cancel BILL'" @click="showCancelBillDialog(slotProps.data)"/>
                    
                </template>
            </Column>

            <template #expansion="slotProps">
                <DataTable :value="slotProps.data.billItems" size="small" >
                    <Column header="Sl No." style="width:2%">
                        <template #body="slotProps">
                            {{ ++slotProps.index }}
                        </template>
                    </Column>
                    <Column field="name" header="Item" />
                    <Column field="hsn" header="HSN/SAC" />
                    <Column field="packing" header="Packing" />
                    <Column field="uom" header="UOM" />
                    <Column header="Mfg/Exp" >
                        <template #body="slotProps" >
                            <label class="text-green-600">
                                {{slotProps.data.mfgDate}}
                            </label>
                            /
                            <label class="text-red-600">
                                {{slotProps.data.expDate}}
                            </label>
                        </template>
                    </Column>                    
                    <Column header="Pt-Sr/Model">
                        <template #body="slotProps" >
                            <label class="text-teal-600">
                                {{slotProps.data.partNo}}
                            </label>/
                            <label class="text-orange-600">
                                {{slotProps.data.modelNo}}
                            </label>
                        </template>
                    </Column>

                    <Column field="quantity" header="Quantity" />
                    <Column field="exGstRate" header="Rate" >
                        <template #body="slotProps">
                            {{formatCurrency(slotProps.data.exGstRate)}}
                        </template>
                    </Column>

                    <Column field="gstRate" header="GST(%)" />
                    <Column field="gst" header="GST" >
                        <template #body="slotProps">
                            {{formatCurrency(slotProps.data.gst)}}
                        </template>
                    </Column>
                    <Column field="exGstSubTotal" header="Ex-GST" >
                        <template #body="slotProps">
                            {{formatCurrency(slotProps.data.exGstSubTotal)}}
                        </template>
                    </Column>
                    <Column header="GST + Ex-GST" >
                        <template #body="slotProps" >
                            {{formatCurrency(slotProps.data.exGstSubTotal + slotProps.data.gst)}}
                        </template>
                    </Column>

                </DataTable>
            </template>
         </DataTable>

        <!-- approve bill -->
         <Dialog :visible="billApproveDialogVisibility" :closable="false" modal :header="billDialogHeader" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                <div v-if="billApproveStatus" class="flex">
                    <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-check-circle" style="font-size: 2.5rem"></i></div>
                    <div class="flex align-items-center justify-content-center ">
                        <p>Once approved this BILL can't be edited. <br> Are you sure you want to approve?</p>
                    </div>
                </div>
                <div v-if="!billApproveStatus" class="flex">
                    <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i></div>
                    <div class="flex align-items-center justify-content-center ">
                        <p>Delivery challans in this bill will be reverted back to pre-bill state. <br> Are you sure you want to cancel?</p>
                    </div>
                    
                </div>

            <div class="flex justify-content-end flex-wrap">
                <Button v-if="billApproveStatus" class="my-1 mr-2 py-2" label="Approve" severity="success" outlined raised @click="confirmBillApproval" />
                <Button v-if="!billApproveStatus" class="my-1 mr-2 py-2" label="Cancel" severity="danger" outlined raised @click="confirmBillCancellation" />
                <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" outlined raised severity="secondary" @click="billApproveDialogVisibility=false"></Button>
            </div>

         </Dialog>
        <!-- approve bill end -->

         <!-- bill print dialog -->
         <!-- <Dialog :visible="billPrintDialogVisibility" modal :dismissableMask="true" maximizable header="Print Bill" :style="{ width: '100rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> -->

            <!-- <BillDoc v-if="billPrintDialogVisibility" :bill="billData" :billitems2="billItems"/>    -->
            <!-- <BillDoc v-if="billPrintDialogVisibility" />    -->

            <!-- <div class="flex justify-content-end flex-wrap">
                <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" outlined severity="secondary" @click="hidePrintDialog"></Button>
            </div>     
                        
        </Dialog> -->
         <!-- bill print dialog end -->
        </template>
        </Card> 
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner'
// import Navbar from '../header/headerNavbar.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { approveBill, cancelBill, billList, procurementOfficerList } from '@/services/apiService'
import Tag from 'primevue/tag'
import { useBillStore } from '@/stores/billStore';
// import BillDoc from './billDoc.vue';
import Dialog from 'primevue/dialog'
import SideBar from '../sidebar/sideBarTwo.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Dropdown from 'primevue/dropdown'

export default {
    
    components:{
        // Navbar,
        ProgressSpinner,
            DataTable,
        Column,
        Card,
        InputText,
        Button,
        Tag,
        // BillDoc,
        Dialog,
        SideBar,
        Dropdown,
    },
    
    data(){
        return {
            spinnerLoadingStatus:false,
            billArr:[],
            expandedRows:[],
            filterQuery:null,
            filteredBills:[],
            billPrintDialogVisibility:false,
            billData:null,
            billItems:[],
            billId:null,
            billApproveDialogVisibility:false,
            billDialogHeader:null,
            billApproveStatus:false,
            filters:null,
            procOfficersArr:[],
        }
    },

    methods:{
        getBills(){
            this.spinnerLoadingStatus = true;
            const query = '?isDraft[eq]=1&isCancelled[eq]=0';
            billList(query).then((res)=>{
                this.billArr = res.data;
                this.filteredBills = res.data;
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
        },
        showBillApprovalDialog(data){
            this.billId = data.id;
            this.billApproveDialogVisibility = true;
            this.billApproveStatus = true;
            this.billDialogHeader = 'Approve Bill :'+data.inv_no;
        },
        billDoc(data){
            const store = useBillStore();
            store.$patch({
                data:data,
            })
        },
        confirmBillApproval(){            
            this.billApproveDialogVisibility = false;
            this.spinnerLoadingStatus = true;

            approveBill({id:this.billId}).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.getBills();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        showCancelBillDialog(data){
            this.billId = data.id;
            this.billApproveDialogVisibility = true;
            this.billApproveStatus = false;
            this.billDialogHeader = 'Cancel Bill :'+data.inv_no;
        },
        confirmBillCancellation(){
            
            this.billApproveDialogVisibility = false;
            this.spinnerLoadingStatus = true;

            cancelBill(this.billId).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.getBills();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        hidePrintDialog(){
            this.billData = null;
            this.billPrintDialogVisibility = false;
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 5000 });
        },   
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
        globalFilter(){
            this.filteredBills = [];
            
            if(this.filterQuery!=null){
                this.billArr.forEach(e => {

                    if (e.inv_no.includes(this.filterQuery) || e.deptPoNo.includes(this.filterQuery) || e.dept.shortCode.includes(this.filterQuery)) { 
                        this.filteredBills.push(e); 
                    }
                    else{
                        e.billItems.every(el => {                         
                            if(el.name.includes(this.filterQuery) || el.hsn.includes(this.filterQuery)){
                                this.filteredBills.push(e);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }
        },
        clearFilter(){
            this.filteredBills = this.billArr;
            this.filterQuery = null;
        },

        getStatus(bill){
            switch (bill.paymentStatus) {
                case '0':
                    return 'Pending'
                case '1':
                    return 'Fully Paid';
                case '-1':
                    return 'Partially Paid';
                default:
                    break;
            }
        },
        getSeverity(bill){
            switch (bill.paymentStatus) {
                case '0':
                    return 'danger';
                case '1':
                    return 'success';
                case '-1':
                    return 'warning';
                default:
                    break;
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'dept.shortCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                procOfficer: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },               
            };
        },
    },
    created() {
        this.initFilters();
    },
    mounted(){

        procurementOfficerList().then((res)=>{
            res.data.forEach(e => {
                this.procOfficersArr.push(e.name)    
            });
        }).catch((error)=>{
            this.showErrorToast(error.response.data)
        })

        this.getBills();
    }

}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>