<template>
  
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>

    <Dialog :visible="paymentDetailsDialog" modal :closable="false" :header="paymentDialogHeader" :style="{ width: '80%' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

        <hr class="w-full"> 
        <div class="mb-4">
            <label class="text-lg font-medium mb-2">
                Details of the payment received
                <span class="text-sm text-400">Options marked as <span class="text-red-500">*</span> are mandatory</span>
            </label>
        </div>

        <div class="formgrid grid">
            <div class="field col-5"> 
                <div class="formgrid grid">
                    <div class="field col">
                        <div>
                            <label >Transaction No.:</label>
                        </div>
                        <div>
                            <InputText v-model="transactionNo" class="w-full"/>
                        </div>
                    </div>
                    <div class="field col">
                        <div>
                            <label >Date:<span class="text-red-500">*</span></label>
                        </div>
                        <div>
                            <Calendar class="w-full" v-model="paymentDate" dateFormat="dd/mm/yy" placeholder="- - Payment date - -"/>
                        </div>
                    </div>                    
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <label >Bank Account:<span class="text-red-500">*</span></label>
                        <div class="flex">
                            <Dropdown  v-model="settlementBank" :options="bankAccounts" optionLabel="accountNo" class="w-full" placeholder="- - Select Bank Account - -" showClear @change="selectBank"/>
                            <!-- <Button class="ml-2" label="New" icon="pi pi-plus" size="small" raised @click="showBankDialog"/> -->
                        </div>
                        <!-- <div class="col-2">
                        </div> -->
                    </div>
                </div>
                <div  class="formgroup-inline">
                    <div v-if="settlementBank" class="field">
                        <label class="mr-1 font-medium text-base">Acc No.:</label>
                        <label class="text-600">{{bankAccNo}}</label> 
                    </div>
                    <div v-if="settlementBank" class="field">
                        <label class="mr-1 font-medium text-base">Holder:</label>
                        <label class="text-600">{{accountHolder}}</label>
                    </div>
                </div>    
                <div class="formgroup-inline">
                    <div v-if="settlementBank" class="field">
                        <label class="mr-1 font-medium text-base">IFSC:</label>
                        <label class="text-600">{{bankIfsc}}</label> 
                    </div>
                    <div v-if="settlementBank" class="field">
                        <label class="mr-1 font-medium text-base">Bank:</label>
                        <label class="text-600">{{accBank}}</label>
                    </div>
                    
                </div>       
            </div>

            <Divider layout="vertical"/>
            <div class="field col ">
                <div class="flex justify-content-between flex-wrap mt-3">
                    <div class="flex align-items-center justify-content-start ">
                        <label >TDS Amount:</label>
                    </div>
                    <div class="flex align-items-center justify-content-end mr-3">
                        <label >{{formatCurrency(tdsAmount)}}</label>
                    </div>
                </div>
                <hr class="amt-style">
                <div class="flex justify-content-between flex-wrap mt-4">
                    <div class="flex align-items-center justify-content-start ">
                        <label >IT deduction:</label>
                    </div>
                    <div class="flex align-items-center justify-content-end mr-3">
                        <label >{{formatCurrency(itAmount)}}</label>
                    </div>
                </div>
                <hr class="amt-style">
                <div class="flex justify-content-between flex-wrap mt-4">
                    <div class="flex align-items-center justify-content-start ">
                        <label >Other deduction:</label>
                    </div>
                    <div class="flex align-items-center justify-content-end mr-3">
                        <label >{{formatCurrency(otherDeductionAmt)}}</label>
                    </div>
                </div>
                <hr class="amt-style">
                <div class="flex justify-content-between flex-wrap mt-4">
                    <div class="flex align-items-center justify-content-start ">
                        <label >Total Net Amount:</label>
                    </div>
                    <div class="flex align-items-center justify-content-end mr-3">
                        <label >{{formatCurrency(settledAmount)}}</label>
                    </div>
                </div>
                <hr class="amt-style">
                
            </div>
        </div>

        <Divider style="width:100%" />

        <div class="flex justify-content-between flex-wrap mt-3">
            <div class="flex align-items-center justify-content-start ">
                <label class="text-lg font-medium">Bills for which payments pending:</label><label class="text-base text-800 ml-2"><span class="text-red-500 mr-1">*</span>Enter the deductions first then click on the checkbox</label>
            </div>
            <div class="flex align-items-center justify-content-end my-3">
                <InputText v-model="filters['global'].value" size="small" placeholder="Keyword Search" />
            </div>
        </div>
        
         <DataTable v-model:selection="selectedBills" :value="billsPendingPayment" size="small" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id" scrollable scrollHeight="300px" showGridlines stripedRows v-model:filters="filters" @row-select="rowSelection" @row-unselect="rowUnselect($event)"   selectAll="false">
                <template #empty> 
                    <div class="flex justify-content-center">
                        No pending payments. 
                    </div>
                </template>
            <Column header="Sl No." style="width:2%">
                <template #body="slotProps">
                    {{ ++slotProps.index }}
                </template>
            </Column>
            <Column field="inv_no" header="Bill No." />
            <Column field="date" header="Bill Date" />
            <Column field="exGstTotal" header="Ex-GST (A)">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.exGstTotal)}}
                </template>
            </Column>
            <Column field="cgst" header="GST (B)" >
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.cgst + slotProps.data.sgst)}}
                </template>
            </Column>
            <Column field="grandTotal" header="Total (A+B)" >
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.grandTotal)}}
                </template>
            </Column>
            <Column header="GST TDS">
                <template #body="slotProps">
                    <InputNumber v-model="gstTds[slotProps.index]"   :minFractionDigits="2"/>
                </template>
            </Column>
            <Column header="IT TDS">
                <template #body="slotProps">
                    <InputNumber v-model="itTds[slotProps.index]"  :minFractionDigits="2"/>
                </template>
            </Column>
            
            <Column header="Other Deduction">
                <template #body="slotProps">
                    <InputNumber v-model="otherDeduction[slotProps.index]"  :minFractionDigits="2"/>
                </template>
            </Column>
            <Column header="Deduction remark">
                <template #body="slotProps">
                    <InputText v-model="otherDeductionRmk[slotProps.index]"  :minFractionDigits="2"/>
                </template>
            </Column>
            <Column header="Net Amount">
                <template #body="slotProps">
                    <InputNumber v-model="netAmount[slotProps.index]" readonly/>
                </template>
            </Column>
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
         </DataTable>
        <template #footer>
            <div class="flex justify-content-end flex-wrap">
                <Button size="small" label="Close" severity="danger" @click="closePaymentDialog" class="mr-2" text raised></Button>
                <Button label="Save" icon="pi pi-save" severity="success" size="small" raised @click="savePayment"/>
            </div>
        </template>
    </Dialog>

     <!-- new bank dialog -->
    <Dialog :visible="newBankDialog" modal :closable="false" header="New bank Details" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <hr class="w-full"> 
        <div class="mb-4">
            <label class="text-lg font-medium mb-2">
                <span class="text-sm text-400">Options marked as <span class="text-red-500">*</span> are mandatory</span>
            </label>
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label >Account No.:<span class="text-red-500">*</span></label>
                <InputNumber v-model="accountNo" class="w-full" :useGrouping="false"/>
            </div>
            <div class="field col">
                <label >Account Holder:<span class="text-red-500">*</span></label>
                <InputText v-model="accountHolder" class="w-full"/>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col">
                <label >IFSC:<span class="text-red-500">*</span></label>
                <InputText v-model="accIfsc" class="w-full"/>
            </div>
            <div class="field col">
                <label>Bank:</label>
                <InputText v-model="accBank" class="w-full"/>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-end flex-wrap">
                <Button size="small" label="Close" severity="danger" @click="closeBankDialog" class="mr-2" text raised></Button>
                <Button label="Save" icon="pi pi-save" severity="success" size="small" raised @click="addNewBank"/>
            </div>
        </template>

    </Dialog>
    <!-- new bank dialog end -->

    

</template>

<script>
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import { FilterMatchMode } from 'primevue/api'
import ProgressSpinner from 'primevue/progressspinner'
import { addBank, getBanks, saveDeptPayment } from '@/services/apiService'

export default {
    name:'BillPaymentComponent',
    components:{
        Dialog,
        Divider,
        InputText,
        InputNumber,
        DataTable,
        Column,
        Button,
        Calendar,
        Dropdown,
    ProgressSpinner,
    },

    props:{
        bills:Object,
    },

    emits:['close-dialog'],

    data(){
        return{
            spinnerLoadingStatus:false,
            billsPendingPayment:[],
            paymentDetailsDialog:false,
            paymentDialogHeader:'Payment details',
            transactionNo:null,
            paymentDate:null,
            bankAccounts:[],
            settlementBank:null,
            bankAccNo:null,
            accountHolder:null,
            bankIfsc:null,
            accBank:null,
            tdsAmount:0,
            itAmount:0,
            otherDeductionAmt:0,
            settledAmount:0,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                billNo: { value: null, matchMode: FilterMatchMode.IN },          
            },
            selectedBills:[],
            gstTds:[],
            itTds:[],
            otherDeduction:[],
            otherDeductionRmk:[],
            netAmount:[],
            accountNo:null,
            accIfsc:null,
            transformedBillData:[],
        }
    },
    methods:{
        selectBank(){
            if(this.settlementBank==null){
                this.bankAccNo = null
                this.bankName = null
                this.bankIfsc = null
                this.accountHolder = null
                this.accBank = null
                return
            }
            this.bankAccNo = this.settlementBank.accountNo
            this.bankName = this.settlementBank.bank
            this.bankIfsc = this.settlementBank.ifsc
            this.accountHolder = this.settlementBank.holder
            this.accBank = this.settlementBank.bank
        },
        showBankDialog(){                
            this.newBankDialog = true
        },
        rowSelection(event){     
            this.gstTds[event.index]==null ? this.gstTds[event.index]=0 : this.gstTds[event.index]
            this.itTds[event.index]==null ? this.itTds[event.index]=0 : this.itTds[event.index]
            this.otherDeduction[event.index]==null ? this.otherDeduction[event.index]=0 : this.otherDeduction[event.index]
            typeof(this.otherDeductionRmk[event.index])=="undefined" ? this.otherDeductionRmk[event.index]=null : this.otherDeductionRmk[event.index]
            
            this.netAmount[event.index] = event.data.grandTotal - this.gstTds[event.index] - this.itTds[event.index] - this.otherDeduction[event.index]
            
            this.tdsAmount += this.gstTds[event.index]
            this.itAmount += this.itTds[event.index]
            this.otherDeductionAmt += this.otherDeduction[event.index]
            this.settledAmount += this.netAmount[event.index]

            this.transformedBillData.push({
                billId:event.data.id,
                gstTds:this.gstTds[event.index],
                itTds:this.itTds[event.index],
                otherDeduc:this.otherDeduction[event.index],
                otherDeducRmk:this.otherDeductionRmk[event.index],
                netAmount:this.netAmount[event.index],
            })  
        },
        rowUnselect(event){
            this.settledAmount -= this.netAmount[event.index]
            this.netAmount[event.index] = 0.0
            this.tdsAmount -= this.gstTds[event.index]
            this.gstTds[event.index] = 0
            this.itAmount -= this.itTds[event.index]
            this.itTds[event.index] = 0
            this.otherDeductionAmt -= this.otherDeduction[event.index]
            this.otherDeduction[event.index] = 0
            const rowIndex = this.transformedBillData.findIndex((e)=>e.billId==event.data.id)
            this.transformedBillData.splice(rowIndex, 1)
        },
        closePaymentDialog(){
            // this.paymentDetailsDialog = false
            // this.selectedBills = []
            // this.transactionNo = null
            // this.paymentDate = null
            // this.settlementBank = null
            // this.bankAccNo = null
            // this.bankName = null
            // this.bankIfsc = null
            // this.tdsAmount = 0
            // this.itAmount = 0
            // this.settledAmount = 0
            // this.gstTds = []
            // this.itTds = []
            // this.netAmount = []
            // this.transformedBillData = []
            // this.closeBankDialog()
            this.$emit('close-dialog', 0, null);
        },
        closeBankDialog(){
            this.newBankDialog = false
            this.accountNo = null     
            this.accountHolder = null
            this.accIfsc = null
            this.accBank = null
        },
        addNewBank(){

        if(this.accountNo==null || this.accountHolder==null || this.accIfsc==null){
            return this.showErrorToast({'message':'Missing required fields!'})
        }
            const data = {
                accountNo:this.accountNo,
                accHolder:this.accountHolder,
                ifsc:this.accIfsc,
                bank:this.accBank,
            }
            
            this.closeBankDialog();
            this.paymentDetailsDialog = false
            this.spinnerLoadingStatus = true
            addBank(data).then((res)=>{
                this.spinnerLoadingStatus = false
                this.$emit('close-dialog', 1, res.data);
            }).catch((error)=>{
                this.spinnerLoadingStatus = false
                this.$emit('close-dialog', 2, error);
            })
        },
        savePayment(){
            if(this.paymentDate==null || this.settlementBank==null){
                return this.showErrorToast({'message':'Some required fields are missing'})
            } 
            if(this.selectedBills.length==0){
                return this.showErrorToast({'message':'Please select atleast one bill!'})
            }

            const data = {
               transactionNo:this.transactionNo,
               paymentDate:this.paymentDate.getFullYear()+'-'+(this.paymentDate.getMonth()+1)+'-'+this.paymentDate.getDate(),
               settlementBank:this.settlementBank,
               tdsAmount:this.tdsAmount,
               itAmount:this.itAmount,
               otherDeductionAmt:this.otherDeductionAmt,
               settledAmount:this.settledAmount,
               selectedBills:this.transformedBillData,    
            }
            
            this.paymentDetailsDialog = false
            this.spinnerLoadingStatus = true
            saveDeptPayment(data).then((res)=>{
                this.spinnerLoadingStatus = false
                this.$emit('close-dialog', 1, res.data);
            }).catch((error)=>{
                this.spinnerLoadingStatus = false
                this.$emit('close-dialog', 2, error);
            })
        },
        paymentDialog(){
            this.spinnerLoadingStatus = true
            this.billsPendingPayment = this.bills.filter(this.filterPaymentStatus)

            getBanks().then((res)=>{
                this.bankAccounts = res.data                
                this.paymentDetailsDialog = true
                this.spinnerLoadingStatus = false
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.spinnerLoadingStatus = false
            })
        },
        filterPaymentStatus(e){
            return e.paymentStatus!='1'
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 5000 });
        },
        
    },
    mounted(){
        this.paymentDialog()
    },
}
</script>

<style>

</style>