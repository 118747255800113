
<template>
<!-- <Navbar/> -->
<SideBar/>
<div class="card main-content mt-3 mx-3">
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>
    <div class="surface-ground ">
    <Card>
        <template #content>
        <DataTable ref="deptPos" :value="filteredPo" v-model:filters="filters" :loading="loading" size="small" tableStyle="min-width: 50rem" paginator :rows="100" :rowsPerPageOptions="[10, 50, 100, 500, 1000]"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" scrollable scrollHeight="70vh" :globalFilterFields="['department', 'procurement_officer']" filterDisplay="menu">
            <template #header >
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span class="text-xl text-900 font-bold">Department PO List</span>
                    <span class="p-input-icon-left">
                        <!-- <i class="pi pi-search" /> -->
                        <InputText v-model="filterQuery" placeholder="Keyword Search" size="small" class="mr-2"/>
                        <Calendar v-model="poDateRange" selectionMode="range" :manualInput="false" placeholder="Select PO date range" />                       
                     </span>
                </div>
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span class="mt-2">
                        <Button label="Export" icon="pi pi-file-export" raised  size="small" @click="exportCSV" v-tooltip.top="'Excel format'"/>
                    </span>
                    <span class="mt-2">
                        <Button class="ml-2" icon="pi pi-filter" severity="success" size="small" @click="globalFilter" v-tooltip.top="'Filter'"/>
                        <Button class="ml-2" icon="pi pi-filter-slash" size="small" outlined severity="success" @click="clearFilter" v-tooltip.top="'Clear Filter'"/>
                    </span>
                </div>
            </template>
            <Column expander style="width: 5rem"/>
            <Column field="po_no" header="PO No."></Column>
            <Column field="roc_refs" header="Ref. ROC" >
                <template #body="slotProps">
                    <Button v-if="slotProps.data.roc_refs!=null && Object.keys(slotProps.data.roc_refs).length != 0" label="Ref ROCs" size="small" @click="showRefRocOp($event, slotProps.data.roc_refs)"/>

                    <!-- </div> -->
                    <OverlayPanel class="overlay-panel-bg" ref="opRefRoc" dismissable>
                        <div v-for="(roc, index) in refRocs" :key="index">
                            <div class="mb-1">
                                <label class="mr-2"><b> {{1 + index}}. </b></label>
                                <label >{{roc}}</label>
                            </div>
                        </div>
                    </OverlayPanel>
                </template>
            </Column>
            <Column field="po_date" header="PO Date" style="width:10%"></Column>
            <Column field="department" header="DEPT" style="width:10%">
                <template #body="slotProps">
                    {{slotProps.data.department}}
                </template>
                <template #filter="{ filterModel }">
                    <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Filter by DEPT" />
                </template>
            </Column>
            <Column field="procurement_officer" header="Proc. Officer" style="width:10%">
                <template #body="slotProps">
                    {{slotProps.data.procurement_officer }}
                </template>
                <template #filter="{ filterModel }">
                    <Dropdown v-model="filterModel.value" :options="procOfficersArr"  placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                        <template #option="slotProps">
                            <label >{{slotProps.option}}</label>
                        </template>
                    </Dropdown>
                </template>
            </Column>
            <Column field="po_value" header="PO Value">
                <template #body="slotProps">
                    {{ formatCurrency(slotProps.data.po_value) }}
                </template>
            </Column>
            <Column field="delivery_period" header="Period (Days)"> days</Column>
            <Column field="order_status" header="Order status" style="width:12%" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }">
                <template #body="slotProps">
                    <Tag :value="getStatus(slotProps.data)" :severity="getSeverity(slotProps.data)" />
                </template>
            </Column>
            <Column header="Payment Terms">
                <template #body="slotProps">
                    <Button label="Show" severity="info" icon="pi pi-window-maximize" size="small" @click="showRemarksDialog(slotProps.data.payment_terms, 1)"/>
                </template>
            </Column>
            <Column header="Other Clause">
                <template #body="slotProps">
                    <Button label="Show" severity="secondary" icon="pi pi-window-maximize" size="small" @click="showRemarksDialog(slotProps.data.other_clause, 2)"/>
                </template>
            </Column>
            
            <template #expansion="slotProps">
                <div class="p-3">
                    <div class="flex flex-wrap align-items-center justify-content-between ">
                     
                         <span>
                            <h3>
                            Department PO items: 
                            </h3>
                        </span> 

                         <span>
                            <!-- Adding of additional items to PO allowed only till items order have not been placed  -->
                            <Button label="Add ITEM" severity="info" size="small" @click="setDeptPoStore(slotProps.data)"/>
                         </span>
                         <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters2['global'].value" placeholder="Keyword Search" size="small"/>
                        </span>
                       
                        <span class="pb-2" v-if="slotProps.data.location_type==-1">
                            <RadioButton class="mx-1" v-model="locationDelivery" value=0 @change="checkSingleLocation(slotProps)"/>
                            <label > Single LD</label> &nbsp;
                            <Dropdown class="pr-1" v-if="singleLocation" v-model="singleDeliveryLocation" filter editable :options="dbLocations" optionLabel="name" optionValue="id" placeholder="Select a location" style="width:9em; height:2.5em"/>
                            <Button class="mx-1" v-if="singleLocation" label="Save" size="small" @click="handleSaveLocation(slotProps.data)"/>
                            <RadioButton class="mx-2" v-model="locationDelivery" value=1 @change="checkSingleLocation(slotProps)"/>
                            <label > Multi LD</label>
                        </span>
                    </div>
                    <DataTable :value="slotProps.data.po_items" v-model:filters="filters2" :virtualScrollerOptions="{ itemSize: 46 }" dataKey="id">
                        <Column header="Sl No.">
                                <template #body="slotProps">
                                    {{ ++slotProps.index }}
                                </template>
                            </Column>
                        <Column field="name" header="Item"></Column>
                        <Column field="rate" header="Rate">
                            <template #body="slotProps">
                                {{ formatCurrency(slotProps.data.rate) }}
                            </template>
                        </Column>
                        <Column field="quantity" header="Quantity"></Column>
                        <Column header="Ordered/Pending">
                            <template #body="slotProps">
                                <label class="text-green-500">{{slotProps.data.orderedQty}}</label>/<label class="text-red-500">{{slotProps.data.pendingQty}}</label> 
                            </template>
                        </Column>
                        <Column field="packing" header="Packing"></Column>
                        <Column field="sub_total" header="Sub Total" >
                            <template #body="slotProps">
                                {{ formatCurrency(slotProps.data.sub_total) }}
                            </template>
                        </Column>
                        
                        <Column v-if="slotProps.data.location_type!=-1 || multiLocation==true" header="Delivery Location">

                            <template #body="slotProps">
                                <span>
                                     <Button v-if="slotProps.data.location_status==1"  label="Show" size="small" icon="pi pi-external-link" @click="showLocations(slotProps.data)" />

                                     <label v-if="slotProps.data.location_status==0 && slotProps.data.cancel_surrender==0" for="label">
                                        <ItemLocation :itemDetails="slotProps.data" :dbLocations="dbLocations" @bind-location="bindData"/>
                                        <Button class="my-1" label="Save" size="small" @click="handleSaveLocation(slotProps.data)"/>    
                                    </label>   
                                </span>
                            </template>
                        </Column>  
                        <Column header="Order Status" style="width:12%">
                            <template #body="slotProps">
                                <Tag :value="getStatus(slotProps.data)" :severity="getSeverity(slotProps.data)" />
                            </template>
                        </Column> 
                        <Column header="Cancel">
                            <template #body="slotProps">
                                <Button :disabled="toggleCancelBtn(slotProps.data)" icon="pi pi-ban" size="small" severity="warning" @click="showCancelDialog(slotProps.data)" v-tooltip.top="'Cancel or Surrender item'"/>
                            </template>
                        </Column>
                        <Column header="OEM PO">
                            <template #body="slotProps">
                                <Button v-if="slotProps.data.location_status==1 && slotProps.data.order_status!=1 && slotProps.data.cancel_surrender=='Active'" class="px-3 py-2" label="Generate" @click="generatePo(slotProps.data)"/>
                                <label v-if="slotProps.data.location_status==0 && slotProps.data.cancel_surrender=='Active'">Set Location</label>
                                <div v-for="(po, index) in slotProps.data.oem_po" :key="index">
                                    <Tag :value="po" severity="info"/>
                                </div>
                                <div v-if="slotProps.data.cancel_surrender=='Cancelled'">
                                    <Tag value="Cancelled" severity="danger"/>
                                </div>
                                <div v-if="slotProps.data.cancel_surrender=='Surrendered'">
                                    <Tag value="Surrendered" severity="warning"/>
                                </div>
                            </template>
                        </Column> 
                        <Column v-if="slotProps.data.order_status!=0" field="storeStock" header="Store Stock"/>
                        <Column v-if="slotProps.data.order_status!=0" header="Delivered">
                            <template #body="slotProps">
                                <Button v-if="slotProps.data.order_status==1" label="Show" severity="success" class="px-3 py-2" @click="showDeliveredStockDialog($event, slotProps.data)"/>
                                <OverlayPanel class="overlay-panel-bg" ref="opDlStock" dismissable>
                                    <h3>Location Delivery</h3>

                                    <DataTable :value="locationStock">
                                        
                                        <Column field="location.name" header="Location"/>
                                        <Column field="quantity" header="Quantity"/>
                                        <Column field="delivered_qty" header="Delivered"/>
                                    </DataTable>

                                <div></div>
                            </OverlayPanel>
                            </template>
                        </Column>
                    
                    </DataTable>

                    <!-- Item location list Dialog -->
                    <Dialog :visible="visible" modal :closable="false" :header="dialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                        
                        <DataTable :value="itemDeliveryLocations" tableStyle="min-width: 30rem">
                            <Column field="location.name" header="Location"></Column>
                            <Column field="quantity" header="Quantity"></Column>
                        </DataTable>
                        <div class="flex justify-content-end flex-wrap">
                            <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" rounded @click="hideDialog"></Button>
                        </div>
                    </Dialog>

                    <!-- cancel or surrenedr item -->
                    <Dialog :visible="cancelDialogVisible" modal :closable="false" :header="cancelDialogHeader" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                        <div class="flex">
                            <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i></div>
                            <div class="flex align-items-center justify-content-center ">
                                <p v-if="piStatus==0">You can either cancel or surrender the item. <br> Once cancelled or surrendered the item will no longer be available for further process. </p>
                                <p v-if="piStatus==1">
                                    The PI for this item has already been received. <br> It cannot be cancelled or surrendered.
                                </p>
                            </div>                            
                        </div>

                        <div v-if="piStatus==0" class="formgrid grid">
                            <div class="field col">
                            <label >Remarks:</label>
                            <Textarea v-model="itemRemark" rows="5" cols="45" />
                            </div>
                        </div>
                                        
                       <template #footer>
                        <div class="flex justify-content-end flex-wrap">
                            <Button v-if="cancelBtn==true && piStatus==0" class="flex align-items-center justify-content-center my-1" size="small" label="Cancel" severity="danger" raised @click="cancelItem"></Button>
                            <Button v-if="piStatus==0" class="flex align-items-center justify-content-center my-1 mx-2" size="small" label="Surrender" severity="warning"  raised @click="surrenderItem"></Button>
                            <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" text raised @click="hideCancelDialog"></Button>
                        </div>
                       </template>
                    </Dialog>
                    <!-- cancel or surrender item end -->
                </div>

            </template>
            <template #footer> In total there are {{ deptPo ? deptPo.length : 0 }} POs. </template>
        </DataTable>

        <Dialog :visible="remarksDialogVisibility" modal :closable="false" :header="remarksDialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

            <div>
                <label>{{orderRemarks}}</label>
            </div>
            <template #footer>
            <div class="flex justify-content-end flex-wrap">
                <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" text raised @click="remarksDialogVisibility=false"></Button>
            </div>
            </template>
        </Dialog>

        </template>
   </Card>
     </div>
</div>
 
</template>


<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import { createDeptPOItemLocation, getDeptPOList, getDeliveryLocations, getDepartments, cancelOrderItem, surrenderOrderItem, procurementOfficerList } from '../../services/apiService'
import RadioButton from 'primevue/radiobutton'
import Button from 'primevue/button'
import ItemLocation from './itemLocation.vue'
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import Tag from 'primevue/tag';
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { useDeptPoStore } from '../../stores/deptPoStore'
import InputText from 'primevue/inputtext';
import OverlayPanel from 'primevue/overlaypanel';
import { useOemPoStore } from '../../stores/oemPo'
import ProgressSpinner from 'primevue/progressspinner';
import SideBar from '../sidebar/sideBarTwo.vue'
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';

export default {
    components:{
        SideBar,
        DataTable, 
        Column, 
        Card, 
        RadioButton, 
        Button, 
        ItemLocation, 
        Dropdown, 
        Dialog, 
        Tag,       
        InputText,
        OverlayPanel,
        ProgressSpinner,
        Textarea,
        Calendar,
    },
    data() {
        return {
            spinnerLoadingStatus:false,
            loading:false,
            deptPo: null,
            deptPoNo:null,
            expandedRows: [],
            singleLocation:false,
            multiLocation:false,
            locationDelivery:null,
            singleDeliveryLocation:'',
            deliveryLocation: [],
            dbLocations:[],
            location:null,
            quantity:null,
            poId:null,
            visible:false,
            itemDeliveryLocations:[],
            showDeliveryLocation:false,
            dialogHeader:'Delivery locations',
            itemHeader:null,
            totalValue:0,
            departments:null,
            selectedDept:null,
            refRocs:[],
            filters: null,
            filters2: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.IN },
            },
            locationStock:[],
            filterQuery:null,
            filteredPo:[],
            cancelDialogVisible:false,
            cancelDialogHeader:null,
            itemRemark:null,
            selectedItem:null,
            cancelBtn:true,
            piStatus:null,
            remarksDialogVisibility:false,
            orderRemarks:null,
            remarksDialogHeader:null,
            procOfficersArr:[],
            poDateRange:null,
        };
    },

    methods:{
        getDepts(){
            this.spinnerLoadingStatus = true;
            getDepartments().then((res)=> 
            {
                this.departments = res.data
                this.spinnerLoadingStatus = false;
            }
            ).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        onRowExpand(data){
            this.poId = data.data.id;
            this.deptPoNo = data.data.po_no;
        },
        getDeptPOs(){
            this.loading = true;
            // this.spinnerLoadingStatus = true;
            getDeptPOList([]).then((res)=> 
            {
                this.filteredPo = res.data;
                this.deptPo = res.data;  
                this.loading = false                 
                // this.spinnerLoadingStatus = false;
            }
            ).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.loading = false
                // this.spinnerLoadingStatus = false;
            });
        },
        getLocations(){
            getDeliveryLocations().then((res)=> 
                {
                    this.dbLocations = res.data
                    
                }
                ).catch((error)=>this.showErrorToast(error.response.data));
        },
        showToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 4000 });
        },
        checkSingleLocation(data){
            
            this.poId = data.data.id
            // For single location delivery
            if(this.locationDelivery==0){
                this.singleLocation = true;
                this.multiLocation = false;
            }
            // For multi location delivery
            if(this.locationDelivery==1){
                this.singleLocation = false;
                this.multiLocation = true;
            }
            
            
        },
        bindData(data){
            this.deliveryLocation = data;
        },
        handleSaveLocation(data){

            if(this.locationDelivery==0){
                 this.deliveryLocation = [];
                

                this.deliveryLocation = [{
                    'location':this.singleDeliveryLocation
                }]
                
                this.spinnerLoadingStatus = true;

                return createDeptPOItemLocation({locations:this.deliveryLocation, totalQuantity:0, locationType:this.locationDelivery, poId:this.poId}).then((res)=> 
                {
                    this.showToast(res.data)
                    
                    this.getDeptPOs();
                    // this.spinnerLoadingStatus = false;
                }
                ).catch((error)=> {
                    this.showErrorToast(error);
                    this.spinnerLoadingStatus = false;
                }); 
            }
            

            let checkQty=0;
            this.locationDelivery = 1;
            if(this.deliveryLocation.length==0){
                this.spinnerLoadingStatus = false;
                return this.showErrorToast({message:'Location cannot be empty!'});
            }
            this.deliveryLocation.forEach(element => {
                checkQty += element.quantity;
                
                if(element.location==null || element.location==""){
                    checkQty = 0;
                    this.spinnerLoadingStatus = false;
                    return this.showErrorToast({message:'Location cannot be empty!'});
                }
            });

            this.spinnerLoadingStatus = true;

            checkQty==data.quantity ? createDeptPOItemLocation({locations:this.deliveryLocation, totalQuantity:data.quantity, locationType:this.locationDelivery, poId:this.poId}).then((res)=> 
            {
                this.showToast(res.data)
                
                this.getDeptPOs();
                // this.spinnerLoadingStatus = false;
            }
            ).catch((error)=> {
                this.showErrorToast(error);
                this.spinnerLoadingStatus = false;
                }) : ()=>{ 
                        this.showErrorToast({message:'Locations quantity does not match with available quantity!'}); 
                        this.spinnerLoadingStatus = false;
                    }
                    

        },
        hideDialog(){
            this.visible = false;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        showLocations(data){
            this.dialogHeader = data.name;
            this.itemDeliveryLocations = [];
            this.visible = false;
            if(data.locations.length>0){
                this.itemDeliveryLocations = {...data.locations};
                this.visible = true;
            }
        },
        getStatus(po){
            switch (po.order_status) {
                case 0:
                    return 'Not Placed'
                case 1:
                    return 'Order Placed';
                case -1:
                    return 'Partially Placed';
                default:
                    break;
            }
        },
        getSeverity(po){
            switch (po.order_status) {
                case 0:
                    return 'danger'
                case 1:
                    return 'success'
                case -1:
                    return 'warning';
                default:
                    break;
            }
        },
        generatePo(data){
            const store = useDeptPoStore();
            store.$patch({
                data:{
                    data,
                    poNo:this.deptPoNo
                },
            });
            this.$router.push('add-oem-po');
        },
        setDeptPoStore(data){
            const store = useDeptPoStore();
            store.$patch({
                data:data,
            });
            this.$router.push('add-dept-po');
        },
        oemPoList(data){
            const store = useOemPoStore();
            store.$patch({
                data:data,
            })
        },
        showDeliveredStockDialog(event, data){
            this.locationStock = data.delivered; 
            this.$refs.opDlStock.toggle(event);
        },
        showRefRocOp(event, data){            
            this.refRocs = []
            this.refRocs = [...data]
            this.$refs.opRefRoc.toggle(event);
        },
        globalFilter(){
            this.loading = true
            var startDate = null;
            var endDate = null;

            if(this.poDateRange!=null && this.poDateRange[0]!=null && this.poDateRange[1]!=null){
                startDate = this.poDateRange[0];
                endDate = this.poDateRange[1];
            }

            this.filteredPo = this.deptPo;
            this.filteredPo = [];

            if(this.filterQuery!=null || (startDate!=null && endDate!=null)){
                this.deptPo.forEach(e => {
                    var deptDate2 = e.po_date.split("-");
                
                    var deptPoDate = new Date(deptDate2[2], (deptDate2[1]-1), deptDate2[0]);                     

                    if (e.po_no.includes(this.filterQuery) || ((startDate!=null && endDate!=null) ? (deptPoDate >= startDate && deptPoDate <= endDate) : false)) { 
                        
                        this.filteredPo.push(e); }
                    else{
                        e.po_items.every(el => {
                            if(el.name.includes(this.filterQuery)){
                                this.filteredPo.push(e);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }

            this.loading = false
        },
        clearFilter(){
            this.loading = true
            this.filteredPo = this.deptPo;
            this.filterQuery = null;
            this.poDateRange = null
            this.loading = false
        },
        showCancelDialog(item){
            this.cancelDialogVisible = true
            this.cancelDialogHeader = 'Cancel/Surrender: '+item.name
            this.selectedItem = item
            if(item.oem_po.length>0){
                this.cancelBtn = false
            }            
            this.piStatus = item.piStatus
        },
        hideCancelDialog(){
            this.cancelDialogVisible = false
            this.cancelDialogHeader = null
            this.selectedItem = null
            this.itemRemark = null
            this.cancelBtn = true
            this.piStatus = null
        },
        cancelItem(){
            const data = {
                id:this.selectedItem.id,
                remark:this.itemRemark
            }
                this.hideCancelDialog()
                this.loading = true
              cancelOrderItem(data).then((res)=>{
                this.getDeptPOs()
                this.showToast(res.data)
                this.loading = false
              }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.loading = false
              })
        },
        surrenderItem(){
            const data = {
                id:this.selectedItem.id,
                remark:this.itemRemark
            }
            this.hideCancelDialog()
            this.loading = true
            surrenderOrderItem(data).then((res)=>{
                this.getDeptPOs()
                this.showToast(res.data)
                this.loading = false
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.loading = false
            })
        },
        exportCSV() {
            this.$refs.deptPos.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                department: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                procurement_officer: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },               
            };
        },
        toggleCancelBtn(item){
            if(item.cancel_surrender!='Active'){
                return true
            }
            return false
        },
        showRemarksDialog(remarks, option){
            option==1 ? this.remarksDialogHeader='Payment Terms' : this.remarksDialogHeader='Other Clause'
            this.remarksDialogVisibility = true
            this.orderRemarks = remarks
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.getDeptPOs();
        this.getLocations();
        procurementOfficerList().then((res)=>{
            res.data.forEach(e => {
                this.procOfficersArr.push(e.name)    
            });
            // this.procOfficersArr = res.data
        }).catch((error)=>{
            this.showErrorToast(error.response.data)
        })

        const store = useDeptPoStore();
        store.$reset();
    }
};
</script>


