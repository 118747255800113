<template>
  
    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Filter Options
                </div>
            </div>
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                  <div class="col">
                    <label class="mt-2">Equipment CMC:</label>                    
                    <Dropdown class="w-full mt-2" v-model="equipCmcStatus" placeholder="Select an option" :options="cmcStatusOptions" optionLabel="label" optionValue="id" showClear @change="toggleInstallationOptions" />
                  </div>
                  <div class="col">
                    <label v-if="installationToggle" class="mt-2">Installation:</label>
                    <Dropdown v-if="installationToggle" class="w-full mt-2" v-model="installationStatus" placeholder="Select an option" :options="equipInstallationStatus" optionLabel="label" optionValue="id" showClear/>
                  </div>
                  <div class="col">
                    <div class="flex">
                        <div class="flex-initial flex align-items-center justify-content-center w-full">
                            <Button label="Filter" raised text severity="success" class="w-full mt-4 mx-2" size="small" @click="filterData()"/>
                        </div>
                        <div class="flex-initial flex align-items-center justify-content-center w-full">
                            <Button label="Clear Filter" raised severity="warning" class="w-full mt-4 " size="small" @click="clearFilter"/>
                        </div>
                    </div>

                  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Equipments Report
                </div>
                <div class="flex align-items-center justify-content-center text-xl mr-2 mb-2">
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="mr-2"/>
                    <Button label="Download report" raised  size="small" style="background-color:#B46076; border-color:#B46076" v-tooltip.top="'Excel format'" @click="exportCSV"/>
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                    <div class="col">
                        <DataTable ref="equipmentsList" :value="equipmentArr" size="small" showGridlines :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" dataKey="id" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="500px">
                            <Column field="name" header="Equipment"></Column>
                            <Column field="poNo" header="PO No."></Column>
                            <Column field="poDate" header="PO Date"></Column>
                            <Column field="quantity" header="Quantity"></Column>
                            <Column header="Pending Installation">
                                <template #body="slotProps">
                                    {{getPendingInstallation(slotProps.data.deliveryLocations)}}
                                </template>
                            </Column>
                            <Column header="DLs">
                                <template #body="slotProps">
                                    <Button label="Show" size="small" @click="showDlDialog(slotProps.data.deliveryLocations)"/>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <!-- delivery locations dialog -->
                <Dialog :visible="dlDialog" modal :closable="false" header="Delivery Locations" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">                                      
                    <DataTable :value="deliveryLocations"  tableStyle="min-width: 30rem">
                        <Column field="location.name" header="Location"></Column>
                        <Column field="quantity" header="Quantity"></Column>
                        <Column field="delivered_qty" header="Delivered"></Column>
                        <Column field="pendingInstallation" header="P. Installation"></Column>
                        <Column header="Installed">
                            <template #body="slotProps">
                                {{ (slotProps.data.delivered_qty - slotProps.data.pendingInstallation) }}
                            </template>
                        </Column>
                    </DataTable>

                    <template #footer>
                        <div class="flex justify-content-end flex-wrap">
                        <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" @click="hideDlDialog"></Button>
                        </div>
                    </template>
                </Dialog>
            <!-- delivery locations dialog end -->

        </div>
    </div>

</template>

<script>
// import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { equipmentDetailsReport } from '@/services/apiService'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
// import Calendar from 'primevue/calendar'
import { FilterMatchMode } from 'primevue/api';

export default {
    components:{
        // InputText,
        Button,
        Dropdown,
        // Calendar,
        DataTable,
        Column,
        Dialog,
        InputText,
    },
    data(){
        return{
            equipCmcStatus:null,
            equipInstallationStatus:[
                {label:'Pending', id:1},
                {label:'Completed', id:2},
            ],
            installationToggle:false,
            installationStatus:null,
            cmcStatusOptions:[
                {label:'Under CMC', id:1},
                {label:'Non CMC', id:2},
            ],
            equipmentArr:[],
            tempArr:[],
            dlDialog:false,
            deliveryLocations:[],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.IN },
                poNo: { value: null, matchMode: FilterMatchMode.IN },
            },
            tableLoading:false,
        }
    },
    methods:{
        fetchEquipments(){
            this.tableLoading = true
            equipmentDetailsReport([]).then((res)=>{
                this.equipmentArr = [...res.data]
                this.tempArr = [...res.data]
                this.calculateTotalValue(this.equipmentArr)
                this.tableLoading = false             
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.tableLoading = false
            })
        },
        toggleInstallationOptions(){
            if(this.equipCmcStatus==null){
                this.installationToggle = false;
                this.installationStatus = null;
                return
            }
            this.installationToggle = true;
        },
        filterData(){
            this.tableLoading = true
            if(this.equipCmcStatus==null && this.installationStatus==null){
                this.equipmentArr = this.tempArr
                this.tableLoading = false
                return
            } 
            this.equipmentArr = this.tempArr.filter(this.filter1)
            this.equipmentArr = this.equipmentArr.filter(this.filter2)
            this.calculateTotalValue(this.equipmentArr)
            this.tableLoading = false
        },
        filter1(e){
            if(this.equipCmcStatus!=null){
                if(this.equipCmcStatus==1 && e.cmc.length>0){
                    return e
                }
                if(this.equipCmcStatus==2 && e.cmc.length==0){
                    return e
                }                        
            }
            else{
                return e
            }
        },
        filter2(e){
            if(this.installationStatus!=null){
                var totalPendingINstallation = 0
                e.deliveryLocations.forEach((el)=> {
                    totalPendingINstallation += el.pendingInstallation
                });
                if(this.installationStatus==1 && totalPendingINstallation>0){
                    return e
                }
                if(this.installationStatus==2 && totalPendingINstallation==0){
                    return e
                }
            }
            else{
                return e
            }
        },
        clearFilter(){
            this.tableLoading = true
            this.equipCmcStatus = null
            this.installationStatus = null
            this.equipmentArr = this.tempArr
            this.installationToggle = false
            this.calculateTotalValue(this.equipmentArr)
            this.tableLoading = false
        },
        calculateTotalValue(items){
            let sum = 0;
            items.forEach(e => {
                sum += e.rate * e.quantity;
            });         
            this.$emit('total-value', sum, 0, 0, 0);
        },
        getPendingInstallation(data){
            var result = 0
            data.forEach(e => {
                result += e.pendingInstallation
            });
            return result
        },
        showDlDialog(dls){
            this.dlDialog = true
            this.deliveryLocations = [...dls]
        },
        hideDlDialog(){
            this.dlDialog = false
            this.deliveryLocations = []
        },
        exportCSV() {
            this.$refs.equipmentsList.exportCSV();
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 4000 });
        },
    },
    mounted(){
        this.fetchEquipments();
    }
}
</script>

<style>

</style>