<template>
  
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>

<!-- new bank dialog -->
    <Dialog :visible="newBankDialog" modal :closable="false" header="New bank Details" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <hr class="w-full"> 
        <div class="mb-4">
            <label class="text-lg font-medium mb-2">
                <span class="text-sm text-400">Options marked as <span class="text-red-500">*</span> are mandatory</span>
            </label>
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label >Account No.:<span class="text-red-500">*</span></label>
                <InputNumber v-model="accountNo" class="w-full" :useGrouping="false"/>
            </div>
            <div class="field col">
                <label >Account Holder:<span class="text-red-500">*</span></label>
                <InputText v-model="accountHolder" class="w-full"/>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col">
                <label >IFSC:<span class="text-red-500">*</span></label>
                <InputText v-model="accIfsc" class="w-full"/>
            </div>
            <div class="field col">
                <label>Bank:</label>
                <InputText v-model="accBank" class="w-full"/>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-end flex-wrap">
                <Button size="small" label="Close" severity="danger" @click="closeBankDialog" class="mr-2" text raised></Button>
                <Button label="Save" icon="pi pi-save" severity="success" size="small" raised @click="addNewBank"/>
            </div>
        </template>

    </Dialog>
    <!-- new bank dialog end -->

</template>

<script>
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import { addBank } from '@/services/apiService'
import ProgressSpinner from 'primevue/progressspinner'

export default {
    name:'AddBank',
    components:{
        Dialog,
        InputText,
        InputNumber,
        Button,
        ProgressSpinner,
    },
    
    emits:['close-dialog'],

    data(){
        return{
            newBankDialog:false,
            accountNo:null,
            accountHolder:null,
            accIfsc:null,
            accBank:null,
            spinnerLoadingStatus:false,
        }
    },
    methods:{
        showBankDialog(){                
                this.newBankDialog = true
        },
        closeBankDialog(){
            this.newBankDialog = false
            this.accountNo = null     
            this.accountHolder = null
            this.accIfsc = null
            this.accBank = null
            this.$emit('close-dialog')
        },
        addNewBank(){

        if(this.accountNo==null || this.accountHolder==null || this.accIfsc==null){
            return this.showErrorToast({'message':'Missing required fields!'})
        }
            const data = {
                accountNo:this.accountNo,
                accHolder:this.accountHolder,
                ifsc:this.accIfsc,
                bank:this.accBank,
            }
            
            this.spinnerLoadingStatus = true
            addBank(data).then((res)=>{
                this.spinnerLoadingStatus = false
                this.$emit('close-dialog', 1, res.data);
            }).catch((error)=>{
                this.spinnerLoadingStatus = false
                this.$emit('close-dialog', 2, error);
            })
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
    },
    mounted(){
        this.newBankDialog = true
    }

}
</script>

<style>

</style>