<template>
  <SideBar/>

<div class="card main-content">
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>
    <div class="grid"> 

        <div class="sm:col-12 md:col-12 lg:col-12">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full ml-3 mr-3 "> 
                <div class="surface-card shadow-2 p-3 border-round-xl w-full mt-4 ">


                    <div class="field font-medium text-lg w-full">
                        <div class="flex justify-content-between flex-wrap">
                            <div class="flex align-items-center justify-content-center font-semibold text-xl  ml-2">                                        
                                    Bill Payments Received                                     
                            </div>
                            <div class="flex align-items-center justify-content-center mt-2">
                                
                                <Button label="Payment Recd" icon="pi pi-plus" size="small" class="ml-2" raised @click="showPaymentDialog"/>
                            </div>                                     
                        </div>
                        <div class="flex justify-content-between flex-wrap">
                            <div class="flex align-items-center justify-content-center mt-2">
                                <Button icon="pi pi-external-link" label="Export" size="small"  raised class="mx-2" @click="exportData($event)" v-tooltip.top="'Export CSV'"/>
                            </div>
                            <div class="flex align-items-center justify-content-center mt-2">
                                <Button label="New Bank" size="small" @click="showNewBankDialog" severity="info" raised />
                            </div>
                        </div>
                    </div>

                    <div class="field col">
                        <DataTable ref="paymentsList" :value="payments" size="small" :loading="tableLoading" v-model:filters="filters"  dataKey="id" paginator :rows="50" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="500px" v-model:expandedRows="expandedRows" :globalFilterFields="['transactionNo', 'bank.account_no', 'date']" filterDisplay="menu">
                            <Column expander style="width: 5rem"/>
                            <Column field="transactionNo" header="Transaction No.">
                                <template #body="slotProps">
                                    {{slotProps.data.transactionNo}}
                                </template>
                                <template #filter="{ filterModel }">
                                    <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by trnsctn no." />
                                </template>
                            </Column>
                            <Column filterField="date" header="Date of Payment" dataType="date">
                                <template #body="slotProps">
                                    {{(slotProps.data.date)}}
                                </template>
                            </Column>
                            <Column field="bank.account_no" header="Account No.">
                                <template #body="slotProps">
                                    {{slotProps.data.bank.account_no}}
                                </template>
                                <template #filter="{ filterModel }">

                                    <Dropdown v-model="filterModel.value" :options="accountNos" placeholder="Select acc. no." class="w-full md:w-14rem" />
  
                                </template>
                            </Column>
                            <Column field="tdsDeduction" header="TDS">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.tdsDeduction)}}
                                </template>
                            </Column>
                            <Column field="itDeduction" header="ITD">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.itDeduction)}}
                                </template>
                            </Column>
                            <Column field="netAmount" header="Settled Amount">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.netAmount)}}
                                </template>
                            </Column>

                            <template #expansion="slotProps">
                            <DataTable :value="slotProps.data.bills" size="small" >
                                <Column header="Sl No.">
                                <template #body="slotProps">
                                    {{
                                        slotProps.index +1
                                    }}
                                </template>
                                </Column>
                                <Column field="billNo" header="Bill No">
                                    <template #body="slotProps">
                                        {{slotProps.data.billNo}}
                                    </template>
                                </Column>
                                <Column field="billDate" header="Bill Date">
                                    <template #body="slotProps">
                                        {{slotProps.data.billDate}}
                                    </template>
                                </Column>
                                <Column field="billExGst" header="Ex-GST">
                                    <template #body="slotProps">
                                        {{formatCurrency(slotProps.data.billExGst)}}
                                    </template>
                                </Column>
                                <Column field="billGst" header="GST">
                                    <template #body="slotProps">
                                        {{formatCurrency(slotProps.data.billGst)}}
                                    </template>
                                </Column>
                                <Column field="gstTds" header="TDS">
                                    <template #body="slotProps">
                                        {{formatCurrency(slotProps.data.gstTds)}}
                                    </template>
                                </Column>
                                <Column field="itTds" header="ITD">
                                    <template #body="slotProps">
                                        {{formatCurrency(slotProps.data.itTds)}}
                                    </template>
                                </Column>
                                <Column field="otherDeduction" header="Other Deduction">
                                    <template #body="slotProps">
                                        {{formatCurrency(slotProps.data.otherDeduction)}}
                                    </template>
                                </Column>
                                <Column field="otherDeducRemark" header="Other Deduc Remark">
                                    <template #body="slotProps">
                                        {{formatCurrency(slotProps.data.otherDeducRemark)}}
                                    </template>
                                </Column>
                                <Column field="netAmount" header="Net Amount">
                                    <template #body="slotProps">
                                        {{formatCurrency(slotProps.data.netAmount)}}
                                    </template>
                                </Column>
                            </DataTable>
                            </template>

                        </DataTable>
                    </div>

                </div>
            </div>
          </div>
        </div>

    </div>
</div>

    <div v-if="bankDialog">
        <AddBank  @close-dialog="closeBankDialog" ></AddBank>
    </div>

    <div v-if="paymentComponent">
        <BillPaymentComponent  :bills="billArr" @close-dialog="closePaymentDialog"></BillPaymentComponent>
    </div>

</template>

<script>
import InputText from 'primevue/inputtext'
import SideBar from '../sidebar/sideBarTwo.vue'
import AddBank from './addBank.vue'
import Button from 'primevue/button'
import { billList, deptPaymentsList, getBanks } from '@/services/apiService'
import ProgressSpinner from 'primevue/progressspinner'
import BillPaymentComponent from './newBillPayment.vue' 
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Dropdown from 'primevue/dropdown'

export default {
    components:{
        SideBar,
        AddBank,
        InputText,
        Button,
    ProgressSpinner,
    BillPaymentComponent,
        DataTable,
        Column,
        Dropdown,
    },
    data(){
        return{
            bankDialog:false,
            paymentComponent:false,
            spinnerLoadingStatus:false,
            billArr:[],
            payments:[],
            tableLoading:false,
            expandedRows:[],
            filters:null,
            accountNos:[],
        }
    },
    methods:{
        getPaymentsList(){
            this.tableLoading = true
            deptPaymentsList().then((res)=>{
                getBanks().then((res)=>{
                    res.data.forEach(e => {
                        this.accountNos.push(e.accountNo)
                    });
                })
                this.tableLoading = false
                this.payments = res.data              
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.tableLoading = false
            })
        },
        showNewBankDialog(){
            this.bankDialog = true
        },
        closeBankDialog(option, msg){
            this.bankDialog = false
            if(option==1){
                this.getPaymentsList()
                this.showSuccessToast(msg)
            }
            if(option==2){
                this.showErrorToast(msg)
            }
        },
        showPaymentDialog(){
            this.spinnerLoadingStatus = true
            const query = '?isApproved[eq]=1&isCancelled[eq]=0';
            billList(query).then((res)=>{
                this.billArr = res.data; 
                this.spinnerLoadingStatus = false                
                this.paymentComponent = true
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false
            })
        },
        closePaymentDialog(option, msg){
            this.paymentComponent = false
            if(option==1){
                this.getPaymentsList()
                this.showSuccessToast(msg)
            }
            if(option==2){
                this.showErrorToast(msg)
            }
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                transactionNo: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'bank.account_no' : { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            };
        }, 
        exportData(){
            this.$refs.paymentsList.exportCSV();
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 4000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
    },
    created() {
        this.initFilters();
    },
    mounted(){
        this.getPaymentsList()
    }
}
</script>

<style>

</style>