<template>
  <!-- <Navbar/> -->
  <SideBar/>

  <div class="card main-content mt-3 mx-3">
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>

    <!-- <div class="surface-ground px-4 py-2"> -->
    <div class="surface-ground">
        <Card>
        <template #content>

         <DataTable ref="bills" v-model:filters="filter2" :loading="table1Loading" :value="filteredBills" size="small" tableStyle="min-width: 50rem" paginator :rows="100" :rowsPerPageOptions="[50, 100, 500, 1000]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" :globalFilterFields="['status']" filterDisplay="menu" scrollable scrollHeight="80vh" >
                <template #header >
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span class="text-xl text-900 font-bold">Bills <Tag value="APPROVED" severity="success" class="mx-3"/> </span>
                    <div class="flex justify-content-end">                        
                        <InputText v-model="filterQuery" placeholder="Keyword Search" size="small"/>
                        <Button class="ml-2" icon="pi pi-filter" severity="success" size="small" @click="globalFilter" v-tooltip.top="'Filter'"/>
                        <Button class="ml-2" icon="pi pi-filter-slash" size="small" outlined severity="success" @click="clearFilter" v-tooltip.top="'Clear Filter'"/>
                    </div>
                     
                </div>
                <div class="flex flex-wrap align-items-center justify-content-between ">
                    <span class="mt-2">
                        <Button icon="pi pi-external-link" label="Export" size="small" raised  @click="exportData($event)" v-tooltip.top="'Export CSV'"/>
                    </span>
                    <span class="mt-2">
                        <Button label="Payment Recd" size="small" icon="pi pi-plus" raised  @click="paymentComponent=true"/>
                    </span>
                </div>
            </template>

            <Column expander style="width: 5rem"/>
            <Column field="inv_no" header="Bill No">
                <template #body="slotProps">
                    <router-link @click="billDoc(slotProps.data)" to="/bill-pdf" >{{slotProps.data.inv_no}}</router-link>
                </template>
            </Column>
            <Column field="date" header="Bill Date" />
            <Column field="deptPoNo" header="PO No." />
            <Column field="deptPoDate" header="PO Date" />    
            <Column field="procOfficer" header="Proc. Officer">
                <template #body="slotProps">
                    {{slotProps.data.procOfficer}}
                </template>
                <template #filter="{ filterModel }">
                    <Dropdown v-model="filterModel.value" :options="procOfficersArr"  placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                        <template #option="slotProps">
                            <label >{{slotProps.option}}</label>
                        </template>
                    </Dropdown>
                </template>
            </Column>         
            <Column field="dept.shortCode" header="Department" >
                <template #body="slotProps">
                    {{slotProps.data.dept.shortCode}}
                </template>
                <template #filter="{ filterModel }">
                    <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Filter by DEPT" />
                </template>
            </Column>
            <Column field="cgst" header="GST" >
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.cgst + slotProps.data.sgst)}}
                </template>
            </Column>

            <Column field="exGstTotal" header="Ex-GST Total">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.exGstTotal)}}
                </template>
            </Column>
            <Column field="adjstValue" header="Adjst Value" />
                <!-- <template #body="slotProps">
                    {{formatCurrency(slotProps.data.adjstValue)}}
                </template> -->
            <!-- </Column> -->
            <Column field="grandTotal" header="Grand Total" >
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.grandTotal)}}
                </template>
            </Column>
            <Column field="status" header="Payment" >
                <template #body="slotProps" >
                    <Tag :value="slotProps.data.status" :severity="paymentStatusSeverity(slotProps.data.status)" />
                </template>
                <template #filter="{ filterModel }">
                    <Dropdown v-model="filterModel.value" :options="paymentStatusOptions" placeholder="Select One" class="p-column-filter" showClear>
                        <template #option="slotProps">
                            <Tag :value="slotProps.option" :severity="paymentStatusSeverity(slotProps.option)" />
                        </template>
                    </Dropdown>
                </template>                
            </Column>

            <template #expansion="slotProps">
                <DataTable :value="slotProps.data.billItems" size="small" >
                    <Column header="Sl No." style="width:2%">
                        <template #body="slotProps">
                            {{ ++slotProps.index }}
                        </template>
                    </Column>
                    <Column field="name" header="Item" />
                    <Column field="hsn" header="HSN/SAC" />
                    <Column field="packing" header="Packing" />
                    <Column field="uom" header="UOM" />
                    <Column header="Mfg/Exp" >
                        <template #body="slotProps" >
                            <label class="text-green-600">
                                {{slotProps.data.mfgDate}}
                            </label>
                            /
                            <label class="text-red-600">
                                {{slotProps.data.expDate}}
                            </label>
                        </template>
                    </Column>                    
                    <Column header="Pt-Sr/Model">
                        <template #body="slotProps" >
                            <label class="text-teal-600">
                                {{slotProps.data.partNo}}
                            </label>/
                            <label class="text-orange-600">
                                {{slotProps.data.modelNo}}
                            </label>
                        </template>
                    </Column>

                    <Column field="quantity" header="Quantity" />
                    <Column field="exGstRate" header="Rate" >
                        <template #body="slotProps">
                            {{formatCurrency(slotProps.data.exGstRate)}}
                        </template>
                    </Column>

                    <Column field="gstRate" header="GST(%)" />
                    <Column field="gst" header="GST" >
                        <template #body="slotProps">
                            {{formatCurrency(slotProps.data.gst)}}
                        </template>
                    </Column>
                    <Column field="exGstSubTotal" header="Ex-GST" >
                        <template #body="slotProps">
                            {{formatCurrency(slotProps.data.exGstSubTotal)}}
                        </template>
                    </Column>
                    <Column header="GST + Ex-GST" >
                        <template #body="slotProps" >
                            {{formatCurrency(slotProps.data.exGstSubTotal + slotProps.data.gst)}}
                        </template>
                    </Column>

                </DataTable>
            </template>
         </DataTable>
        </template>
        </Card> 
    </div>

    <div v-if="paymentComponent">
        <BillPaymentComponent  :bills="billArr" @close-dialog="closePaymentDialog"></BillPaymentComponent>
    </div>   

    <!-- new bank dialog -->
    <Dialog :visible="newBankDialog" modal :closable="false" :header="bankDialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <hr class="w-full"> 
        <div class="mb-4">
            <label class="text-lg font-medium mb-2">
                <span class="text-sm text-400">Options marked as <span class="text-red-500">*</span> are mandatory</span>
            </label>
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label >Account No.:<span class="text-red-500">*</span></label>
                <InputNumber v-model="accountNo" class="w-full" :useGrouping="false"/>
            </div>
            <div class="field col">
                <label >Account Holder:<span class="text-red-500">*</span></label>
                <InputText v-model="accountHolder" class="w-full"/>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col">
                <label >IFSC:<span class="text-red-500">*</span></label>
                <InputText v-model="accIfsc" class="w-full"/>
            </div>
            <div class="field col">
                <label>Bank:</label>
                <InputText v-model="accBank" class="w-full"/>
            </div>

        </div>

        <template #footer>
            <div class="flex justify-content-end flex-wrap">
                <Button size="small" label="Close" severity="danger" @click="closeBankDialog" class="mr-2" text raised></Button>
                <Button label="Save" icon="pi pi-save" severity="success" size="small" raised @click="addNewBank"/>
            </div>
        </template>

    </Dialog>
    <!-- new bank dialog end -->

  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { addBank, billList, getBanks, procurementOfficerList, saveDeptPayment } from '@/services/apiService'
import Tag from 'primevue/tag'
import { useBillStore } from '@/stores/billStore';
import SideBar from '../sidebar/sideBarTwo.vue'
import Dialog from 'primevue/dialog'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import BillPaymentComponent from './newBillPayment.vue'

export default {
    
    components:{
        ProgressSpinner,
        DataTable,
        Column,
        Card,
        InputText,
        Button,
        Tag,
        SideBar,
        Dialog,
        InputNumber,
        Dropdown,
        BillPaymentComponent,
    },
    
    data(){
        return {
            table1Loading:false,
            spinnerLoadingStatus:false,
            paymentComponent:false,
            billArr:[],
            expandedRows:[],
            filterQuery:null,
            filteredBills:[],
            paymentDetailsDialog:false,
            paymentDialogHeader:'Payment details',
            bankAccounts:[],
            recdAmount:0.0,
            paymentDate:null,
            transactionNo:null,
            percOfAmt:100,
            percTds:0.0,
            percIt:0.0,
            settlementBank:null,
            settledAmount:0,
            billsPendingPayment:[],
            selectedBills:[],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                billNo: { value: null, matchMode: FilterMatchMode.IN },          
            },
            filter2:null,
            percBillAmtToggle:true,
            taxableAmount:0,
            tdsAmount:0,
            itAmount:0,
            gstTds:[],
            itTds:[],
            otherDeduction:[],
            otherDeductionRmk:[],
            otherDeductionAmt:0,
            netAmount:[],
            bankAccNo:null,
            bankName:null,
            bankIfsc:null,
            newBankDialog:false,
            bankDialogHeader:'New bank Details',
            accountHolder:null,
            accountNo:null,
            accIfsc:null,
            accBank:null,
            transformedBillData:[],
            paymentStatusOptions:['Pending', 'Partially Recd', 'Fully Recd'],
            procOfficersArr:[],
        }
    },

    methods:{
        getBills(){
            this.table1Loading = true;
            const query = '?isApproved[eq]=1&isCancelled[eq]=0';
            billList(query).then((res)=>{
                this.billArr = res.data;
                this.filteredBills = res.data;
                this.table1Loading = false;                 
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.table1Loading = false;
            })
        },
        billDoc(data){
            const store = useBillStore();
            store.$patch({
                data:data,
            })
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 5000 });
        },   
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
        globalFilter(){
            this.filteredBills = [];
            
            if(this.filterQuery!=null){
                this.billArr.forEach(e => {

                    if (e.inv_no.includes(this.filterQuery) || e.deptPoNo.includes(this.filterQuery) || e.dept.shortCode.includes(this.filterQuery)) { 
                        this.filteredBills.push(e); 
                    }
                    else{
                        e.billItems.every(el => {                         
                            if(el.name.includes(this.filterQuery) || el.hsn.includes(this.filterQuery)){
                                this.filteredBills.push(e);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }
        },
        clearFilter(){
            this.filteredBills = this.billArr;
            this.filterQuery = null;
        },
        getStatus(bill){
            switch (bill.paymentStatus) {
                case '0':
                    return 'Pending'
                case '1':
                    return 'Fully Paid';
                case '-1':
                    return 'Partially Paid';
                default:
                    break;
            }
        },
        getSeverity(bill){
            switch (bill.paymentStatus) {
                case '0':
                    return 'danger';
                case '1':
                    return 'success';
                case '-1':
                    return 'warning';
                default:
                    break;
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        paymentStatusSeverity(status){
            switch (status) {
                case 'Pending':
                    return 'danger';
                case 'Fully Recd':
                    return 'success';
                case 'Partially Recd':
                    return 'warning';
                default:
                    break;
            }
        },
        paymentDialog(){
            this.spinnerLoadingStatus = true
            this.billsPendingPayment = this.billArr.filter(this.filterPaymentStatus)

            getBanks().then((res)=>{
                this.bankAccounts = res.data                
                this.paymentDetailsDialog = true
                this.spinnerLoadingStatus = false
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.spinnerLoadingStatus = false
            })
        },
        filterPaymentStatus(e){
            return e.paymentStatus!='1'
        },
        closePaymentDialog(option, msg){
            this.paymentComponent=false
            this.paymentDetailsDialog = false
            this.selectedBills = []
            this.transactionNo = null
            this.paymentDate = null
            this.settlementBank = null
            this.bankAccNo = null
            this.bankName = null
            this.bankIfsc = null
            this.tdsAmount = 0
            this.itAmount = 0
            this.settledAmount = 0
            this.gstTds = []
            this.itTds = []
            this.netAmount = []
            this.transformedBillData = []
            this.closeBankDialog()
            if(option==1){
                this.showSuccessToast(msg)
                this.getBills()
            }
            if(option==2){
                this.showErrorToast(msg)
            }
        },
        rowSelection(event){     
            this.gstTds[event.index]==null ? this.gstTds[event.index]=0 : this.gstTds[event.index]
            this.itTds[event.index]==null ? this.itTds[event.index]=0 : this.itTds[event.index]
            this.otherDeduction[event.index]==null ? this.otherDeduction[event.index]=0 : this.otherDeduction[event.index]
            typeof(this.otherDeductionRmk[event.index])=="undefined" ? this.otherDeductionRmk[event.index]=null : this.otherDeductionRmk[event.index]
            
            this.netAmount[event.index] = event.data.grandTotal - this.gstTds[event.index] - this.itTds[event.index] - this.otherDeduction[event.index]
            
            this.tdsAmount += this.gstTds[event.index]
            this.itAmount += this.itTds[event.index]
            this.otherDeductionAmt += this.otherDeduction[event.index]
            this.settledAmount += this.netAmount[event.index]

            this.transformedBillData.push({
                billId:event.data.id,
                gstTds:this.gstTds[event.index],
                itTds:this.itTds[event.index],
                otherDeduc:this.otherDeduction[event.index],
                otherDeducRmk:this.otherDeductionRmk[event.index],
                netAmount:this.netAmount[event.index],
            })  

        },
        rowUnselect(event){
            this.settledAmount -= this.netAmount[event.index]
            this.netAmount[event.index] = 0.0
            this.tdsAmount -= this.gstTds[event.index]
            this.gstTds[event.index] = 0
            this.itAmount -= this.itTds[event.index]
            this.itTds[event.index] = 0
            this.otherDeductionAmt -= this.otherDeduction[event.index]
            this.otherDeduction[event.index] = 0
            const rowIndex = this.transformedBillData.findIndex((e)=>e.billId==event.data.id)
            this.transformedBillData.splice(rowIndex, 1)
        },
        allRowSelection(){
        //     this.taxableAmount = 0
        //     this.percOfAmt = 0.0
            
        //     event.data.forEach(e => {
        //         this.taxableAmount += e.exGstTotal
        //     });
        },
        rowUnselectAll(){
            // this.selectedBills = []
            // this.taxableAmount = 0,
            // this.recdAmount = 0.0
            // this.percOfAmt = 0.0
            // this.tdsAmount = 0
            // this.itAmount = 0
            // this.settledAmount = 0

        },  
        selectBank(){


            if(this.settlementBank==null){
                this.bankAccNo = null
                this.bankName = null
                this.bankIfsc = null
                this.accountHolder = null
                this.accBank = null
                return
            }
            this.bankAccNo = this.settlementBank.accountNo
            this.bankName = this.settlementBank.bank
            this.bankIfsc = this.settlementBank.ifsc
            this.accountHolder = this.settlementBank.holder
            this.accBank = this.settlementBank.bank

        },
        showBankDialog(){                
                this.newBankDialog = true
        },
        closeBankDialog(){
            this.newBankDialog = false
            this.accountNo = null     
            this.accountHolder = null
            this.accIfsc = null
            this.accBank = null
        },
        savePayment(){

            if(this.paymentDate==null || this.settlementBank==null){
                return this.showErrorToast({'message':'Some required fields are missing'})
            } 
            if(this.selectedBills.length==0){
                return this.showErrorToast({'message':'Please select atleast one bill!'})
            }

            const data = {
               transactionNo:this.transactionNo,
               paymentDate:this.paymentDate.getFullYear()+'-'+(this.paymentDate.getMonth()+1)+'-'+this.paymentDate.getDate(),
               settlementBank:this.settlementBank,
               tdsAmount:this.tdsAmount,
               itAmount:this.itAmount,
               otherDeductionAmt:this.otherDeductionAmt,
               settledAmount:this.settledAmount,
               selectedBills:this.transformedBillData,    
            }
            
            this.closePaymentDialog()
            this.spinnerLoadingStatus = true
            saveDeptPayment(data).then((res)=>{
                this.showSuccessToast(res.data)
                this.spinnerLoadingStatus = false
                this.getBills()
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.spinnerLoadingStatus = false
            })
        },
        addNewBank(){

        if(this.accountNo==null || this.accountHolder==null || this.accIfsc==null){
            return this.showErrorToast({'message':'Missing required fields!'})
        }

            const data = {
                accountNo:this.accountNo,
                accHolder:this.accountHolder,
                ifsc:this.accIfsc,
                bank:this.accBank,
            }
            
            this.closeBankDialog();
            this.closePaymentDialog()
            this.spinnerLoadingStatus = true
            addBank(data).then((res)=>{
                this.showSuccessToast(res.data)
                this.spinnerLoadingStatus = false
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.spinnerLoadingStatus = false
            })
        },
        initFilters() {
            this.filter2 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                procOfficer: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }, 
                'dept.shortCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            };
        },
        exportData(){
            this.$refs.bills.exportCSV();
        },
    },
    created() {
        this.initFilters();
    },
    mounted(){
        procurementOfficerList().then((res)=>{
            res.data.forEach(e => {
                this.procOfficersArr.push(e.name)    
            });
        }).catch((error)=>{
            this.showErrorToast(error.response.data)
        })
        this.getBills();
    }

}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

hr.amt-style{
    border-top: 1px dashed #d5d8dc;
    margin-right: 1rem;
}

</style>